/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAppointmentActiveByIdentification = /* GraphQL */ `
  query GetAppointmentActiveByIdentification(
    $input: queryActiveAppointmentInput
  ) {
    getAppointmentActiveByIdentification(input: $input) {
      items {
        id
        identification
        date
        appointmentSlot
        appointmentAppointmentTypeId
        name
        lastname
        details
      }
      nextToken
    }
  }
`;
export const getAvailableSlotsByAppointmentType = /* GraphQL */ `
  query GetAvailableSlotsByAppointmentType(
    $appointmentTypeId: ID!
    $nextToken: String
    $group: String
  ) {
    getAvailableSlotsByAppointmentType(
      appointmentTypeId: $appointmentTypeId
      nextToken: $nextToken
      group: $group
    ) {
      slots {
        id
        date
        slot
        slotAppointmentTypeId
        capacity
        balance
        group
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const validateCaptcha = /* GraphQL */ `
  query ValidateCaptcha($msg: String) {
    validateCaptcha(msg: $msg)
  }
`;
export const getProceedingInfo = /* GraphQL */ `
  query GetProceedingInfo($proceedingNumber: String!) {
    getProceedingInfo(proceedingNumber: $proceedingNumber) {
      accountNumber
      actorIdCard
      errorMessage
      isPayment
      proceedingAmount
      proceedingNumber
      proceedingStatusName
      proceedingStatusNameReal
      result
    }
  }
`;
export const getAppointmentsByDate = /* GraphQL */ `
  query GetAppointmentsByDate($input: queryAppointmentInput) {
    getAppointmentsByDate(input: $input) {
      items {
        id
        name
        lastname
        identification
        phone
        email
        details
        date
        appointmentSlot
        appointmentAppointmentTypeId
        vehiclePlateNumber
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
        slot {
          id
          date
          slot
          slotAppointmentTypeId
          capacity
          balance
          group
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listAppointmentTypes = /* GraphQL */ `
  query ListAppointmentTypes(
    $filter: ModelAppointmentTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointmentTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointmentType = /* GraphQL */ `
  query GetAppointmentType($id: ID!) {
    getAppointmentType(id: $id) {
      id
      title
      instructions
      requirements
      form
      group
      systemName
      messageConfirmation
      createdAt
      updatedAt
    }
  }
`;
export const listSlots = /* GraphQL */ `
  query ListSlots(
    $filter: ModelSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        slot
        slotAppointmentTypeId
        capacity
        balance
        group
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSlot = /* GraphQL */ `
  query GetSlot($id: ID!) {
    getSlot(id: $id) {
      id
      date
      slot
      slotAppointmentTypeId
      capacity
      balance
      group
      createdAt
      updatedAt
      appointmentType {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
    }
  }
`;
export const slotsbyAppointmentTypeByDateBySlot = /* GraphQL */ `
  query SlotsbyAppointmentTypeByDateBySlot(
    $slotAppointmentTypeId: ID
    $dateSlot: ModelSlotByAppointmentTypeByDateBySlotCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slotsbyAppointmentTypeByDateBySlot(
      slotAppointmentTypeId: $slotAppointmentTypeId
      dateSlot: $dateSlot
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        slot
        slotAppointmentTypeId
        capacity
        balance
        group
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      name
      lastname
      identification
      phone
      email
      details
      date
      appointmentSlot
      appointmentAppointmentTypeId
      vehiclePlateNumber
      createdAt
      updatedAt
      appointmentType {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
      slot {
        id
        date
        slot
        slotAppointmentTypeId
        capacity
        balance
        group
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lastname
        identification
        phone
        email
        details
        date
        appointmentSlot
        appointmentAppointmentTypeId
        vehiclePlateNumber
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
        slot {
          id
          date
          slot
          slotAppointmentTypeId
          capacity
          balance
          group
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAppointmentsByVehiclePlate = /* GraphQL */ `
  query GetAppointmentsByVehiclePlate(
    $vehiclePlateNumber: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    GetAppointmentsByVehiclePlate(
      vehiclePlateNumber: $vehiclePlateNumber
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        lastname
        identification
        phone
        email
        details
        date
        appointmentSlot
        appointmentAppointmentTypeId
        vehiclePlateNumber
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
        slot {
          id
          date
          slot
          slotAppointmentTypeId
          capacity
          balance
          group
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
