import React , { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper, TextField, Grid, Typography, Button, FormControl, InputLabel, Select} from '@material-ui/core';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { getAppointmentActiveByIdentification } from '../../graphql/queries';
import { listAppointmentTypes } from '../../graphql/queries';
import ReCAPTCHA from 'react-google-recaptcha';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import  {format, parse, addDays} from "date-fns";
import { es } from 'date-fns/locale';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  }
}));

export default function AppointmentSearch(){
    const classes = useStyles();
    const [identification, setIdentification] = useState('');
    const [appointmentTypes, setAppointmentTypes] = useState([])
    const [catpchaToken, setCatpchaToken] = useState('');
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [appointmentTypeSelected, setAppointmentTypeSelected] = useState('');
    const [appointmentData, SetAppointmentData] = useState();
    const [isFound, setIsFound] = useState(true);
    const [appointmentTypesObject, setAppointmentTypesObject] = useState({});
     useEffect(() => {
      fetchAppointmentType()
    }, [])

    function AddDetails(appData){
    try{
      if(appData != null){
         for (var i=0, n=appData.length; i < n; ++i){
              var jsonDetail = appData[i].details ? JSON.parse(appData[i].details): "";
              appData[i].proceedingNumber = jsonDetail.proceedingNumber ? jsonDetail.proceedingNumber: "";
              appData[i].accountNumber = jsonDetail.accountNumber ? jsonDetail.accountNumber: "";
              appData[i].typeProceeding = jsonDetail.typeProceeding ? jsonDetail.typeProceeding: "";
              appData[i].vehiclePlateNumber = jsonDetail.vehiclePlateNumber ? jsonDetail.vehiclePlateNumber: "";

          }
      }
         
    }
    catch(ex){
       console.log("Error llenando detalles de cita "+ ex);
    }

  }
    const recaptchaRef = React.createRef();
    var handleCaptcha = function(key) {
      const response = key;
      setCatpchaToken(response);
    }

    
    async function fetchAppointmentType() {
    try {
      const AppointmentTypeData = await API.graphql({ ...graphqlOperation(listAppointmentTypes), authMode: "API_KEY" });
      const AppointmentTypes = AppointmentTypeData.data.listAppointmentTypes.items;
      setAppointmentTypes(AppointmentTypes);
    }
    catch (err) {
      console.log('error fetching AppointmentTypes', err);
    }
  }
    
    async function handleClickSearchAppointment(event) {
      SetAppointmentData('');
      if (appointmentTypeSelected === undefined || appointmentTypeSelected === '' || appointmentTypeSelected.length == 0) {
         setErrorMessage("Debe ingresar el tipo de cita a consultar!");
         setOpen(true);
         return;
      }
      else if (identification === undefined || identification === '' || identification.length == 0) {
        setErrorMessage("Favor ingresar número de identificación");
        setOpen(true);
        return;
      }
     
      else if (catpchaToken === undefined || catpchaToken === null || catpchaToken.length == 0) {
         setErrorMessage("Favor validar que no es un robot");
         setOpen(true);
         return;
      }
      else { }
     
      const queryAppointmentInput = {
      	identification:identification, 
      	appointmentType: appointmentTypeSelected, 
      	captcha: catpchaToken
      };
      const appointment = await API.graphql({ ...graphqlOperation(getAppointmentActiveByIdentification, {input: queryAppointmentInput }), authMode: "API_KEY" });
      debugger
      console.log(appointment);
      AddDetails(appointment.data.getAppointmentActiveByIdentification.items);

      SetAppointmentData(appointment.data.getAppointmentActiveByIdentification.items[0]);
      if(appointment.data.getAppointmentActiveByIdentification.items.length ==1) {
        setIsFound(true);
      }
      else {
        setIsFound(false);
      }
    }
    
 const onChange = (event) => {
    if (event.target === undefined) {
      return;
    }
    if (event.target.name == "appointmentType") {
      setAppointmentTypeSelected(event.target.value);
      var appointTypeObjectSelected = appointmentTypes.filter(obj =>{
          if(obj.id === event.target.value){
            return obj;
          }
      })[0];
      setAppointmentTypesObject(appointTypeObjectSelected);
     }
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  function createMarkup(htmlMarkup) {
    return {__html: htmlMarkup};
  }

 
    return(
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Consulta de Cita
          </Typography>
          <React.Fragment>
            <Grid item xs={12}>
               <FormControl fullWidth={true}>
                <InputLabel id="appointmentType-label">Tipo de cita</InputLabel>
                <Select
                  labelId="appointmentType-label"
                  id="appointmentType"
                  value={appointmentTypeSelected}
                  onChange={onChange}
                  name="appointmentType"
                  required
                  >
                  {appointmentTypes.map(appType => (
                         <MenuItem value={appType.id} key={appType.id}>{appType.title}</MenuItem>
                      ))}
                  </Select>
              </FormControl>
              <FormControl fullWidth={true}>
                <TextField
                  id="standard-full-width"
                  label="Identificación de la persona que acudirá a la cita"
                  //style={{ margin: 8 }}
                  placeholder="Ingresar número de identificación"
                  helperText=""
                  fullWidth
                  value= {identification}
                  onChange={e => setIdentification(e.target.value)}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                  </FormControl>
                   {(appointmentData === undefined | appointmentData==='')? '':(  <React.Fragment>
                    <Typography variant="subtitle1" gutterBottom>
                     Estimado contribuyente, usted tiene una cita agendada para el día 
                     <Box  fontWeight="fontWeightBold">{format(parse(appointmentData.date,'yyyyMMdd',new Date()),'EEEE dd/MMMM/yyyy',{locale:es}) } de {appointmentData.appointmentSlot} hrs
                     </Box>
                    </Typography>
                    {appointmentData.vehiclePlateNumber=== '' ? '' :(<React.Fragment>
                  
                      <Typography gutterBottom><Box fontWeight="fontWeightBold" m={1} align="left">Nombre y Apellido : {appointmentData.name +' '+ appointmentData.lastname}</Box></Typography>
                      <Typography gutterBottom><Box fontWeight="fontWeightBold" m={1} align="left">Placa : {appointmentData.vehiclePlateNumber}</Box></Typography>
                    </React.Fragment>)}
                  
                    <Typography variant="h6" display="block" gutterBottom >
                    <Box fontWeight="fontWeightBold">
                            IMPORTANTE
                    </Box>
                    </Typography>
                      <Typography variant="body1" gutterBottom>
                        {
                            <div dangerouslySetInnerHTML={createMarkup((appointmentTypesObject.requirements ? appointmentTypesObject.requirements : ""))} />
                        }
                    </Typography>
                    </React.Fragment>)
                  }
                  {isFound ? '' : (<React.Fragment>
                    <Typography variant="subtitle1" gutterBottom>
                     <Box  fontWeight="fontWeightBold">
                         No se encontró cita para la identificación proporcionada
                     </Box>
                    </Typography>
                    </React.Fragment>)
                    
                    
                  }
                   <ReCAPTCHA 
                      onChange={handleCaptcha}
                      ref={recaptchaRef}
                       hl= "es"
                      //This ref can be used to call captcha related functions in case you need.
                      sitekey="6Lft2AAVAAAAAJB30dHi44tVH-g5F35hF51WKUtS"
                      theme="light"
                      
              
                    />
                     <Snackbar open={open} onClose={handleClose} >
                      <Alert onClose={handleClose} severity="error">
                        {errorMessage}
                      </Alert>
                    </Snackbar>
            </Grid>
            <br/>
            <Button variant="contained" color="primary" onClick={handleClickSearchAppointment}>
              Buscar
            </Button>
          </React.Fragment>
        </Paper>
        );
}