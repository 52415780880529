import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import  {format, parse, addDays} from "date-fns";
import { es } from 'date-fns/locale'
import Box from '@material-ui/core/Box';
const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function AppointmentConfirmation(data) {
 function createMarkup(htmlMarkup) {
    return {__html: htmlMarkup};
  }
  const classes = useStyles();
  return (
    <React.Fragment>
      
      <Grid container spacing={2} justify="center">
          <Typography variant="h5" gutterBottom>
            <Box  m={1} align="center">
               Su cita se ha creado de forma exitosa!
           </Box>
        </Typography>
        <Grid item container direction="column" xs={12} sm={8}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            <Box  m={1} align="center">
              Información de la Cita
            </Box>
          </Typography>
          <Grid container justify="center">
            <React.Fragment>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography gutterBottom>
                   <Box fontWeight="fontWeightBold" m={1} align="center">
                   Tipo de Cita
                  </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography gutterBottom> <Box  m={1} align="center">{data.data.appointmentAppointmentTypeTitle}</Box></Typography>
                </Grid>
            </React.Fragment>
            <React.Fragment>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography gutterBottom>
                   <Box fontWeight="fontWeightBold" m={1} align="center">
                   Identificación
                  </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography gutterBottom> <Box  m={1} align="center">{data.data.identification}</Box></Typography>
                </Grid>
              </React.Fragment>
            <React.Fragment>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography gutterBottom><Box fontWeight="fontWeightBold" m={1} align="center">Nombre y Apellido</Box></Typography>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography gutterBottom> <Box  m={1} align="center">{data.data.name +' '+ data.data.lastname} </Box></Typography>
                </Grid>
              </React.Fragment>
              <React.Fragment>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography gutterBottom> <Box  fontWeight="fontWeightBold" m={1} align="center">Fecha Cita </Box></Typography>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography gutterBottom>
                   <Box  m={1} align="center">
                  {format(parse(data.data.date,'yyyyMMdd',new Date()),'EEEE dd/MMMM/yyyy',{locale:es}) + " " +data.data.appointmentSlot}
                  </Box>
                  </Typography>
                </Grid>
              </React.Fragment>
          </Grid>
        </Grid>
      </Grid>
       <Typography variant="h6" display="block" gutterBottom >
                      <Box fontWeight="fontWeightBold">
                       IMPORTANTE
                      </Box>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {
                          <div dangerouslySetInnerHTML={createMarkup((data.data.requirements ? data.data.requirements : ""))} />
                      }
      </Typography>
    </React.Fragment>
  );
}