
  const appTypeSystemName = {
    appTypeSysNameVehiclePlate : "VehiclePlateDelivery",
    appTypeSysNamePubConsult : "PCT",
    appTypeSysNamePubNotification : "PCN",
    appTypeSysNameOYCIP : "OYCIP",
    appTypeSysNameOYCRPC : "OYCRPC",
    appTypeSysNameOYCRPA : "OYCRPA"
  };
   
  export default appTypeSystemName;