import React,{ useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import Box from '@material-ui/core/Box';

export default function SolicitantForm({ onChange, appointmentTypes, formState, formId}) {
  
  
  function createMarkup(htmlMarkup) {
    return {__html: htmlMarkup};
  }
  
  function onChangeSelection(event){
    onChange(event);
    var aType = appointmentTypes.find(appType =>{
    return appType.id == event.target.value}) ;
    onChange({target:{name:"appointmentTypeObject", value:aType}});
  }
  return (
    <div id={formId}>
    <React.Fragment >
      <Typography variant="h6" gutterBottom>
        Solicitante
      </Typography>
       <Typography variant="caption" display="block" gutterBottom>
         <Box fontWeight="fontWeightBold" >
            Se debe ingresar los datos de la persona que acudirá a la cita
         </Box>
      </Typography>
      <form validate="true">
      <Grid container spacing={3}>
        <Divider />
        
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Nombre"
            fullWidth
            value= {formState.name}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastname"
            label="Apellido"
            fullWidth
            value= {formState.lastname}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="identification"
            name="identification"
            label="Cédula / Pasaporte"
            fullWidth
            value= {formState.identification}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField id="phone" name="phone" label="Teléfono" required fullWidth onChange={onChange} value= {formState.phone}/>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Correo"
            type="email"
            
            helperText={formState.emailError}
            fullWidth
            value= {formState.email}
            onChange={onChange}
          />
        </Grid>
       
      </Grid>
       

      </form>
    </React.Fragment>
    </div>
  );
}