import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

import { format, parse, addDays } from "date-fns";
import { es } from 'date-fns/locale';
import { withAuthenticator, AmplifySignOut, AmplifyAuthenticator } from '@aws-amplify/ui-react';
import uuid from 'react-uuid'
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { listAppointmentTypes, listSlots } from '../../graphql/queries';
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import Box from '@material-ui/core/Box';
import { Paper,Fade, CircularProgress, TextField, Typography, FormControl, InputLabel, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AdminNewSlotsTable from './components/AdminNewSlotsTable';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CachedIcon from '@material-ui/icons/Cached';
import { createSlot } from '../../graphql/mutations';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import appTypeSystemName from '../appTypeSystemName';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
     margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
}));

const buttonRef = React.createRef();

 
function AdminNewSlots() {
    const classes = useStyles();
 
    const genderItems = [
        { id: 'M', title: 'Masculino' }
        , { id: 'F', title: 'Femenino' }
    ];
    const slotItems = [
        { id: '07:00 - 08:00', title: '07:00 - 08:00' }
        , { id: '08:00 - 09:00', title: '08:00 - 09:00' }
        , { id: '09:00 - 10:00', title: '09:00 - 10:00' }
        , { id: '10:00 - 11:00', title: '10:00 - 11:00' }
        , { id: '11:00 - 12:00', title: '11:00 - 12:00' }
        , { id: '12:00 - 13:00', title: '12:00 - 13:00' }
        , { id: '13:00 - 14:00', title: '13:00 - 14:00' }
        , { id: '14:00 - 15:00', title: '14:00 - 15:00' }
        , { id: '15:00 - 16:00', title: '15:00 - 16:00' }
        , { id: '16:00 - 17:00', title: '16:00 - 17:00' }
        , { id: '17:00 - 18:00', title: '17:00 - 18:00' }

    ];
   

    const slotModel = {
        id: uuid(),
        date: '',
        slot: '',
        slotAppointmentTypeId: '',
        slotAppointmentTypeName: '',
        group: [],
        capacity: 0,
        balance: 0,
        loading: false,
        saved : false,
        genderName: '',
        genderId: '',
        lastDigitIdCardValue: '',
        systemName:''
    };

    const [errorMessage, setErrorMessage] = React.useState("");
    const [slotData, setSlotData] = React.useState(slotModel)
    const [appointmentTypeSelected, setAppointmentTypeSelected] = useState('');
    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [gender, setGender] = useState([]);
    const [genderSelected, setGenderSelected] = useState('');
    const [slotType, setSlotType] = useState([]);
    const [checkedElderly, setCheckedElderly] = React.useState(false);
    const [lastDigitIdCard, setLastDigitIdCard] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState([])
    const [editMode, setEditMode] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [countErrors, setCountErrors] = useState(0);
    const handleClose = (event, reason) => {
    
        setOpen(false);
    };
     const onRowDelete = (event, id) => {
         
        var adata = data.filter(function( obj ) {
            return obj.id !== id;
        });
       
        setData(adata);
        setOpenSuccess(true);
        setSuccessMessage("Registro eliminado!");
    };
     const onRowEdit = (event, id) => {
        
        var adata = data.filter(function( obj ) {
            return obj.id === id;
        })[0];
        slotData.id =  adata.id;
        slotData.date = format(parse(adata.date,'yyyyMMdd',new Date()),'yyyy-dd-MM',{locale:es}) 
        slotData.slot= adata.slot;
        slotData.slotAppointmentTypeId= adata.slotAppointmentTypeId;
        slotData.slotAppointmentTypeName= adata.slotAppointmentTypeName;
        slotData.group= adata.group;
        slotData.capacity= adata.capacity;
        slotData.balance= adata.balance;
        slotData.loading= adata.loading;
        slotData.saved=  adata.saved;
        slotData.genderId=  adata.genderId;
        slotData.lastDigitIdCardValue=  adata.lastDigitIdCardValue;
        setSlotData(slotData);
        setLastDigitIdCard(adata.lastDigitIdCardValue);
        //COMBOS
        setAppointmentTypeSelected(slotData.slotAppointmentTypeId);
        setGenderSelected(adata.genderId);
  
        setSuccessMessage("El registro a editar se muestra en los controles superiores");
        setOpenSuccess(true);
        
        setEditMode(true);
       
    };

    const onChange = (event) => {
        if (event.target === undefined) {
            return;
        }
        if (event.target.name == "appointmentType") {
            setAppointmentTypeSelected(event.target.value);
            setInput("slotAppointmentTypeId", event.target.value);
                var appTypeObject = appointmentTypes.filter(function( obj ) {
                return (obj.id === event.target.value);
            })[0];
            setInput("systemName", appTypeObject.systemName);
        }
        if (event.target.name == "gender") {
            setGenderSelected(event.target.value);
            var arrayData = slotData;
            arrayData.genderId = event.target.value;
            
            arrayData.genderName = document.getElementById("gender").innerHTML;
            setSlotData(arrayData);
            setInput("genderId", event.target.value);
            if (lastDigitIdCard != undefined) {
                var groups = [];
                var group1 = event.target.value + lastDigitIdCard;
                groups.push(group1);
                if (checkedElderly) {
                    groups.push(event.target.value + "T");
                }
                setInput("group", groups);
            }
        }
    }


    useEffect(() => {
        fetchAppointmentType();
        fetchGenders();
        fetchSlotTypes();
    }, [])

    async function fetchGenders() {
        try {

            setGender(genderItems);
        } catch (err) {
            console.log('error fetching Genders');
            console.log(err);
        }
    }
    async function fetchSlotTypes() {
        try {
            setSlotType(slotItems);
        } catch (err) {
            console.log('error fetching slotTypes');
            console.log(err);
        }
    }
    async function fetchAppointmentType() {
    try {
      const AppointmentTypeData = await API.graphql({ ...graphqlOperation(listAppointmentTypes), authMode: "API_KEY" });
      const AppointmentTypes = AppointmentTypeData.data.listAppointmentTypes.items;
      setAppointmentTypes(AppointmentTypes);
    }
    catch (err) {
      console.log('error fetching AppointmentTypes', err);
    }
  }
    const handleCheckBoxChange = (event) => {
        setCheckedElderly(event.target.checked);
    }

    function setInput(key, value) {
        
        if (key == "lastDigitIdCard") {
            
            setLastDigitIdCard(value);
            var array = slotData;
            array.lastDigitIdCardValue = value;
            setSlotData(array);
        }
        else if (key == "checkedElderly") {
            setCheckedElderly(value);
        }
        else {
            setSlotData({ ...slotData, [key]: value })
            
        }

    }

    const handleInputChange = (event) => {
        if (event.target.name === "lastDigitIdCard" || event.target.name === "checkedElderly") {
            var groups = []
            if (event.target.name === "lastDigitIdCard") {
                
                if(event.target.value.length > 1) {
                    setErrorMessage("La capacidad no puede superar un digito");
                    setOpen(true);
                    return;
                }
                else {
                    var group1 = genderSelected + event.target.value;
                    groups.push(group1);
                    if (slotData.group.length > 1) {
                        groups.push(slotData.group[1]);
                    }
                    setInput(event.target.name, event.target.value);
                }
            }

            else if (event.target.name === "checkedElderly") {
                if (event.target.checked) {
                    var group1 = slotData.group[0];
                    groups.push(group1);
                    var group2 = genderSelected == "M" ? "MT" : "FT";
                    groups.push(group2);

                    setInput(event.target.name, event.target.checked);
                }
                else {
                    var group1 = slotData.group[0];
                    groups.push(group1);
                    setInput(event.target.name, event.target.checked);
                }
            }
            else {

                setInput(event.target.name, event.target.value);
            }
            setInput("group", groups);
        }
        
        else {
            setInput(event.target.name, event.target.value);
        }
    }
    async function handleClickClean(event) {
       cleanData();
    }
    function cleanData() {
        
        setSlotData(slotModel);
        setLastDigitIdCard('');
        setGenderSelected('');
        setCheckedElderly(false);
        setAppointmentTypeSelected('');
    }
    async function handleProcessSaveSlot(event) {
        try {
            //Realizar el guardado 
            setCount(0);
             setCountErrors(0);
            setLoading(true);
            var countSuccess = 0;
            var countError = 0;
            for(var i = 0; i<data.length;i++) {
                
                //realizar el guardado de solo los elementos que no han sido almacenados
                if(!data[i].saved){ 
                   let newArr = [...data]; // copying the old datas array
                   newArr[i].loading = true; 
                   
                  
                   let paramsToPush = {
                              id:data[i].id,
                              date: parseInt(data[i].date),
                              slot: data[i].slot,
        	                    slotAppointmentTypeId: data[i].slotAppointmentTypeId,
        	                    group: data[i].group,
        	                    capacity: parseInt(data[i].capacity),
        	                    balance: parseInt(data[i].capacity)
        	               
                        };
                   var filter = {};
                  //Validar si no se encuentra el registro antes de proseguir a insertarlo
                  if(data[i].group.length == 1)
                  {
                      filter = { 
                      and: [
                            { date: { eq: paramsToPush.date } },
                            { slot: { eq: paramsToPush.slot } },
                            { slotAppointmentTypeId: { eq: paramsToPush.slotAppointmentTypeId } },
                            { balance: { gt: 0 } },
                            { group: { contains: paramsToPush.group[0] } }
                          ] 
                      
                       };
                  }
                  
                 if(data[i].group.length == 2) {
                      filter = { 
                      and: [
                            { date: { eq: paramsToPush.date } },
                            { slot: { eq: paramsToPush.slot } },
                            { slotAppointmentTypeId: { eq: paramsToPush.slotAppointmentTypeId } },
                            { balance: { gt: 0 } },
                            { group: { contains: paramsToPush.group[0] } },
                             { group: { contains: paramsToPush.group[1]  } }
                          ] 
                      
                       };
                  }
                  
                 //verificar que el registro no ha sido registrado en base de datos
                  var slotsDataFromDb = await API.graphql(graphqlOperation(listSlots, { filter: filter }));
                  var appData = slotsDataFromDb.data.listSlots.items;
                  if(appData.length == 0) {
                    while (slotsDataFromDb.data.listSlots.nextToken) {
              
                        slotsDataFromDb = await API.graphql(graphqlOperation(listSlots, { filter: filter, nextToken: slotsDataFromDb.data.listSlots.nextToken }));
                        appData = appData.concat(slotsDataFromDb.data.listSlots.items);
                         if(appData.length > 0) {
                             break;
                         }
              
                    }
                  }
                  // Si no se encuentra informacion registrada, se guarda el nuevo cupo
                  if(appData.length == 0) {
                    const resp = await API.graphql({ ...graphqlOperation(createSlot, { input: paramsToPush }) });  
                    countSuccess = countSuccess + 1;
                  }
                  else {
                    countError = countError + 1
                  }
                        setData(newArr); 
                        data[i].loading = true;
                        newArr[i].loading = false; 
                        newArr[i].saved = true; 
                        setData(newArr); 
                    }
            }
             setCount(countSuccess);  
             setCountErrors(countError);
             setData([]);
            
        }
                catch (error) {
        if (error instanceof ValidateHandleNextException) {
          setErrorMessage(error.message);
          setOpen(true);
          setLoading(false);
          return;
        }
        else {
          console.log("Error", error);
          var errorMessage = "Ha ocurrido un error al enviar su solicitud, intente  nuevamente.";
          setErrorMessage(errorMessage);
          setOpen(true);
          setLoading(false);
        }
      }
      finally {
        setLoading(false);
      }
    }
    function ValidateHandleNextException(message) {
        this.message = (message || "");
    }
    function validateHandleNext(){
        if (appointmentTypeSelected == "") {
            throw new ValidateHandleNextException("Debe seleccionar el tipo de cita!");
        }
        else if (slotData.capacity == 0) {
            throw new ValidateHandleNextException("Debe ingresar la capacidad");
        }
        else if (slotData.date == "") {
            throw new ValidateHandleNextException("Debe ingresar la fecha");
        }
        else if (slotData.slot == "") {
            throw new ValidateHandleNextException("Debe ingresar el horario");
        }
        switch (slotData.systemName) {
      
            // 1. Retiro de Placa
            case appTypeSystemName.appTypeSysNameVehiclePlate:
                
                if (genderSelected == "") {
                    throw new ValidateHandleNextException("Debe seleccionar el genero!");
                }
                /*else if (lastDigitIdCard == "") {
                    throw new ValidateHandleNextException("Debe ingresar el último digito identificación!");
                }*/
                
            break;
            // 2. Cita para consulta de trámites
            case appTypeSystemName.appTypeSysNamePubConsult:
            
              break;
            // 3. Cita para Notificación
            case appTypeSystemName.appTypeSysNamePubNotification:
             
              break;
            default:
            // code block
          }
           //Validar que no exista el registro en el grid
           if(slotData.group.length == 1) {
            var adata = data.filter(function( obj ) {
                return (obj.slotAppointmentTypeId === appointmentTypeSelected
                        && obj.slot === slotData.slot
                        && obj.date === format(parse(slotData.date,'yyyy-MM-dd',new Date()),'yyyyMMdd',{locale:es}) 
                        && obj.group[0] === slotData.group[0]
                        );
            });
            if(adata.length > 0) {
                setErrorMessage("Ya existe un registro agregado en la tabla con las configuraciones seleccionadas!");
                setOpen(true);
                return;
            }
        }
        else if(slotData.group.length > 1) {
            var adata = data.filter(function( obj ) {
                return (
                        obj.slotAppointmentTypeId === appointmentTypeSelected
                        && obj.slot === slotData.slot
                        && obj.date === format(parse(slotData.date,'yyyy-MM-dd',new Date()),'yyyyMMdd',{locale:es}) 
                        && obj.group[0] === slotData.group[0]
                        && obj.group[1] === slotData.group[1]
                        );
            });
            if(adata.length > 0) {
                setErrorMessage("Ya existe un registro agregado en la tabla con las configuraciones seleccionadas!");
                setOpen(true);
                return;
            }
        }
    }
    async function handleClickAddSlot(event) {
        try {
            validateHandleNext();
           
            var objects = data.length == 0 ?  [] : data;
            slotData.date = format(parse(slotData.date,'yyyy-MM-dd',new Date()),'yyyyMMdd',{locale:es}) 
            slotData.slotAppointmentTypeId = appointmentTypeSelected;
            slotData.slotAppointmentTypeName = document.getElementById("appointmentType").innerHTML;
            if(slotData.systemName != appTypeSystemName.appTypeSysNameVehiclePlate)
            {
                var groups = [];
                groups.push(slotData.systemName);
                slotData.group = groups;
            }

            //Validar que no exista el registro en base de datos
            objects.unshift(slotData);
            //Guardar nuevo slot
            setData(objects);
            cleanData();
        }
        catch (error) {
            if (error instanceof ValidateHandleNextException) {
              setErrorMessage(error.message);
              setOpen(true);
              return;
            }
            else {
              console.log("Error", error);
              var errorMessage = "Ha ocurrido un error al enviar su solicitud, intente  nuevamente.";
              if (error.errors != undefined && error.errors !== null && error.errors.length > 0) {
                const resultError = JSON.parse(error.errors[0].errorType);
                errorMessage = error.errors[0].message;
                errorMessage = errorMessage.replace("{date}", format(parse(resultError.date, 'yyyyMMdd', new Date()), 'EEEE dd/MM/yyyy', { locale: es }));
                errorMessage = errorMessage.replace("{appointmentSlot}", resultError.appointmentSlot);
              }
              setErrorMessage(errorMessage);
              setOpen(true);
            }
          }
          finally {
            setLoading(false);
          }
    }
    async function handleClickUpdateSlot(event) {
        
        var objIndex = data.findIndex((obj => obj.id == slotData.id));
        data[objIndex].id =  slotData.id;
        data[objIndex].date = format(parse(slotData.date,'yyyy-dd-MM',new Date()),'yyyyMMdd',{locale:es}) 
        data[objIndex].slot= slotData.slot;
        data[objIndex].slotAppointmentTypeId= slotData.slotAppointmentTypeId;
        data[objIndex].slotAppointmentTypeName= slotData.slotAppointmentTypeName;
        data[objIndex].group= slotData.group;
        data[objIndex].capacity= slotData.capacity;
        data[objIndex].balance= slotData.balance;
        data[objIndex].loading= slotData.loading;
        data[objIndex].saved=  slotData.saved;
        data[objIndex].genderId=  slotData.genderId;
        data[objIndex].lastDigitIdCardValue=  slotData.lastDigitIdCardValue;
        setSlotData(data);
        setEditMode(false);
        cleanData();
    }
    return (
        <React.Fragment>
            <Typography variant="h2" gutterBottom>
                Crear Horario
             </Typography>
            <Typography variant="caption" display="block" gutterBottom >

                <Box fontWeight="fontWeightBold">
                    Se debe ingresar los datos del cupo a configurar
                </Box>
            </Typography>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth={true}>
                        <InputLabel id="appointmentType-label">Tipo de cita</InputLabel>
                        <Select
                            labelId="appointmentType-label"
                            id="appointmentType"
                            value={appointmentTypeSelected}
                            onChange={onChange}
                            name="appointmentType"
                            required
                        >
                            {appointmentTypes.map(appType => (
                                <MenuItem value={appType.id} key={appType.id}>{appType.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {slotData.systemName == appTypeSystemName.appTypeSysNameVehiclePlate? 
                    <React.Fragment>
                    <FormControl fullWidth={true}>
                        <InputLabel id="gender-label">Genero</InputLabel>
                        <Select
                            labelId="gender-label"
                            id="gender"
                            value={genderSelected}
                            onChange={onChange}
                            name="gender"
                            required
                        >
                            {gender.map(gen => (
                                <MenuItem value={gen.id} key={gen.id}>{gen.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                      </React.Fragment>
                    : ""
                    }
                    <TextField
                        //required
                        id="lastDigitIdCard"
                        name="lastDigitIdCard"
                        label="Último Digito Identificación"
                        fullWidth
                        type="number"
                        value={lastDigitIdCard}
                        onChange={handleInputChange}
                        style={{display: false ? 'block' : 'none' }}
                    />
                    <TextField
                        required
                        id="capacity"
                        name="capacity"
                        label="Capacidad"
                        fullWidth
                        type="number"
                        value={slotData.capacity}
                        onChange={handleInputChange}

                    />
                
                    <TextField
                        required
                        id="date"
                        name="date"
                        label="Fecha"
                        fullWidth
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={slotData.date}
                        onChange={handleInputChange}
                    />
                    {slotData.systemName != appTypeSystemName.appTypeSysNameVehiclePlate? 
                    <React.Fragment>
                         <TextField
                            required
                            id="slot"
                            name="slot"
                            label="Horario"
                            fullWidth
                            type="text"
                            placeholder="hh:mm - hh:mm"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={slotData.slot}
                            onChange={handleInputChange}
                        />
                     </React.Fragment>
                    : ""
                    }
                    {slotData.systemName == appTypeSystemName.appTypeSysNameVehiclePlate? 
                    <React.Fragment>
                        <FormControl fullWidth={true}>
                            <InputLabel id="slotType-label">Horario</InputLabel>
                            <Select
                                labelId="slot-label"
                                id="slot"
                                value={slotData.slot}
                                onChange={handleInputChange}
                                name="slot"
                                required
                            >
                                {slotType.map(slotT => (
                                    <MenuItem value={slotT.id} key={slotT.id}>{slotT.title}</MenuItem>
                                ))}
    
                            </Select>
                        </FormControl>
                     </React.Fragment>
                    : ""
                    }
                    {slotData.systemName == appTypeSystemName.appTypeSysNameVehiclePlate? 
                    <React.Fragment>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedElderly}
                                onChange={handleInputChange}
                                name="checkedElderly"
                                id="checkedElderly"
                                color="primary"
                            />
                        }
                        label="Tercera Edad?"
                    />
                    </React.Fragment>
                    : ""
                    }
                </Grid>
                <Grid item xs={12}   >
                    { editMode ?
                    (<React.Fragment>
                        <Button variant="contained"  className={classes.margin} onClick={handleClickUpdateSlot} startIcon={<CachedIcon />}>
                            Modificar
                        </Button>
                    </React.Fragment>)
                    : ""}
                    { !editMode ?
                    (<React.Fragment>
                    <Button variant="contained"  className={classes.margin} onClick={handleClickAddSlot} startIcon={<AddCircleOutlineIcon />}>
                        Agregar
                    </Button>
                     </React.Fragment>)
                    : ""}
                    { !editMode ?
                      (<React.Fragment>
                        <Button variant="contained"  className={classes.margin} color="secondary" onClick={handleClickClean} startIcon={<ClearIcon />} disabled={data.length==0}>
                            Limpiar
                        </Button>
                    </React.Fragment>)
                     : ""}
                    { !editMode ?
                    (<React.Fragment>
                        <Button variant="contained" className={classes.margin} color="primary" onClick={handleProcessSaveSlot} startIcon={<SaveIcon />} disabled={data.length==0}>
                            Guardar
                        </Button>
                          <Fade
                            in={loading}
                            style={{
                              transitionDelay: loading ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                          >
                          <Box position="relative" display="inline-flex">
                            <CircularProgress />
                            <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography variant="caption" component="div" color="textSecondary">
                             &nbsp;Guardando Datos....  {count}
                            </Typography>
                          </Box>
                          </Box>
                          </Fade>
          
                    </React.Fragment>)
                    : ""}
                </Grid>
                {count == 0 ? '' : (<React.Fragment>
                    <Grid item xs={12} >
                            <Typography variant="subtitle1" gutterBottom>
                             <Box  fontWeight="fontWeightBold">
                                 Se procesaron {count} registros exitosamente!
                             </Box>
                            </Typography>
                    </Grid>
                   </React.Fragment>) 
                            
                            
                }
                {countErrors == 0 ? '' : (<React.Fragment>
                    <Grid item xs={12} >
                    <Typography variant="subtitle1" gutterBottom color="error">
                     <Box  fontWeight="fontWeightBold" color="text.error">
                         Se han encontrado {countErrors} registro (s) existente en el sistema.
                     </Box>
                    </Typography>
                       </Grid>
                    </React.Fragment>)
                    
                    
                }
                <Snackbar open={open} onClose={handleClose} autoHideDuration={6000}>
                    <Alert onClose={handleClose} severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
                <Snackbar open={openSuccess} onClose={handleClose} autoHideDuration={6000}>
                    <Alert
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpenSuccess(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    >
                   {successMessage}
                    </Alert>
                 </Snackbar>

            </Grid>
            <AdminNewSlotsTable 
              rows = {data}
              onDelete = {onRowDelete}
              onEdit = {onRowEdit}
              
            />
          
        </React.Fragment>

    );
}

export default withAuthenticator(AdminNewSlots);