import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography, CssBaseline, IconButton, Button, BottomNavigation, BottomNavigationAction, ListItemIcon, MenuItem, ListItemText, Menu } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import ScheduleIcon from '@material-ui/icons/Schedule';

const useStyles = makeStyles(() => ({
  root: {
    
  },
  grow: {
    flexGrow: 1,
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;
  const [value, setValue] = React.useState(null);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <RouterLink to="/">
          <MenuItem onClick={handleMenuClose} >
          <ListItemIcon>
              <ScheduleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Agendar Cita" />
        </MenuItem>
      </RouterLink>
      <RouterLink to="/appointment-search">
          <MenuItem onClick={handleMenuClose} >
          <ListItemIcon>
            <PageviewIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Consultar Cita" />
        </MenuItem>
      </RouterLink>
      
    </Menu>
  );
  
  
  
  return (
    <React.Fragment>
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="default"
      position="fixed"
    >
      <Toolbar>
          <img
            alt="Logo"
            src="../../../favicon.ico"
          />
        <Typography variant="h7" color="inherit" align="left" noWrap>
          Aplicación de Citas
        </Typography>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.root}
          style={{ backgroundColor: '#f5f5f5'}}
        >
          <BottomNavigationAction label="Agendar" icon={<ScheduleIcon />} component={RouterLink} to="/"/>
          <BottomNavigationAction label="Consultar" icon={<PageviewIcon />} component={RouterLink} to="/appointment-search" />
        </BottomNavigation>
        <div className={classes.grow} />
        <img alt="Alcaldía de Panamá" src="../../logo_pma.jpg" style={{ height:"95px"}} />
      </Toolbar>
    </AppBar>
    </React.Fragment>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
