import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

export default function AppointmentTypeForm({onChange, appointmentTypes, formState, formId}) {
  
  
  function createMarkup(htmlMarkup) {
    return {__html: htmlMarkup};
  }
  
  function onChangeSelection(event){
    onChange(event);
    var aType = appointmentTypes.find(appType =>{
    return appType.id == event.target.value}) ;
    onChange({target:{name:"appointmentTypeObject", value:aType}});
  }
  return (
    <div id={formId}>
    <React.Fragment >
      <form validate="true">
      <Grid container spacing={3}>
      <Grid item xs={12}>
      
         <FormControl fullWidth={true}>
        <InputLabel id="appointmentType-label">Tipo de cita</InputLabel>
        <Select
          labelId="appointmentType-label"
          id="appointmentType"
          value={formState.appointmentType}
          onChange={onChange}
          name="appointmentType"
          required
        >
        {appointmentTypes.map(appType => (
               <MenuItem value={appType.id} key={appType.id}>{appType.title}</MenuItem>
            ))}
        </Select>
      </FormControl>
      
        </Grid>
        <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          {
             <div dangerouslySetInnerHTML={createMarkup((formState.appointmentTypeObject ? formState.appointmentTypeObject.instructions : ""))} />
          }
      </Typography>
        </Grid>
      </Grid>
      </form>
    </React.Fragment>
    </div>
  );
}