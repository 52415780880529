/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const scheduleAppointment = /* GraphQL */ `
  mutation ScheduleAppointment($input: scheduleAppointmentInput!) {
    scheduleAppointment(input: $input) {
      id
      name
      lastname
      identification
      phone
      email
      details
      date
      appointmentSlot
      appointmentAppointmentTypeId
      vehiclePlateNumber
      createdAt
      updatedAt
      appointmentType {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
      slot {
        id
        date
        slot
        slotAppointmentTypeId
        capacity
        balance
        group
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const validateAppointmentDetails = /* GraphQL */ `
  mutation ValidateAppointmentDetails(
    $input: validateAppointmentDetailsInput!
  ) {
    validateAppointmentDetails(input: $input) {
      warnings
      errors
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($input: EmailModelInput!) {
    sendEmail(input: $input)
  }
`;
export const createAppointmentType = /* GraphQL */ `
  mutation CreateAppointmentType(
    $input: CreateAppointmentTypeInput!
    $condition: ModelAppointmentTypeConditionInput
  ) {
    createAppointmentType(input: $input, condition: $condition) {
      id
      title
      instructions
      requirements
      form
      group
      systemName
      messageConfirmation
      createdAt
      updatedAt
    }
  }
`;
export const updateAppointmentType = /* GraphQL */ `
  mutation UpdateAppointmentType(
    $input: UpdateAppointmentTypeInput!
    $condition: ModelAppointmentTypeConditionInput
  ) {
    updateAppointmentType(input: $input, condition: $condition) {
      id
      title
      instructions
      requirements
      form
      group
      systemName
      messageConfirmation
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppointmentType = /* GraphQL */ `
  mutation DeleteAppointmentType(
    $input: DeleteAppointmentTypeInput!
    $condition: ModelAppointmentTypeConditionInput
  ) {
    deleteAppointmentType(input: $input, condition: $condition) {
      id
      title
      instructions
      requirements
      form
      group
      systemName
      messageConfirmation
      createdAt
      updatedAt
    }
  }
`;
export const createSlot = /* GraphQL */ `
  mutation CreateSlot(
    $input: CreateSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    createSlot(input: $input, condition: $condition) {
      id
      date
      slot
      slotAppointmentTypeId
      capacity
      balance
      group
      createdAt
      updatedAt
      appointmentType {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSlot = /* GraphQL */ `
  mutation UpdateSlot(
    $input: UpdateSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    updateSlot(input: $input, condition: $condition) {
      id
      date
      slot
      slotAppointmentTypeId
      capacity
      balance
      group
      createdAt
      updatedAt
      appointmentType {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSlot = /* GraphQL */ `
  mutation DeleteSlot(
    $input: DeleteSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    deleteSlot(input: $input, condition: $condition) {
      id
      date
      slot
      slotAppointmentTypeId
      capacity
      balance
      group
      createdAt
      updatedAt
      appointmentType {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment(
    $input: UpdateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    updateAppointment(input: $input, condition: $condition) {
      id
      name
      lastname
      identification
      phone
      email
      details
      date
      appointmentSlot
      appointmentAppointmentTypeId
      vehiclePlateNumber
      createdAt
      updatedAt
      appointmentType {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
      slot {
        id
        date
        slot
        slotAppointmentTypeId
        capacity
        balance
        group
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment(
    $input: DeleteAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    deleteAppointment(input: $input, condition: $condition) {
      id
      name
      lastname
      identification
      phone
      email
      details
      date
      appointmentSlot
      appointmentAppointmentTypeId
      vehiclePlateNumber
      createdAt
      updatedAt
      appointmentType {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
      slot {
        id
        date
        slot
        slotAppointmentTypeId
        capacity
        balance
        group
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment(
    $input: CreateAppointmentInput!
    $condition: ModelAppointmentConditionInput
  ) {
    createAppointment(input: $input, condition: $condition) {
      id
      name
      lastname
      identification
      phone
      email
      details
      date
      appointmentSlot
      appointmentAppointmentTypeId
      vehiclePlateNumber
      createdAt
      updatedAt
      appointmentType {
        id
        title
        instructions
        requirements
        form
        group
        systemName
        messageConfirmation
        createdAt
        updatedAt
      }
      slot {
        id
        date
        slot
        slotAppointmentTypeId
        capacity
        balance
        group
        createdAt
        updatedAt
        appointmentType {
          id
          title
          instructions
          requirements
          form
          group
          systemName
          messageConfirmation
          createdAt
          updatedAt
        }
      }
    }
  }
`;
