/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:4505e119-ac1a-47d6-8627-e957cc60e6d9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_5YlElU7n4",
    "aws_user_pools_web_client_id": "69hqhktvmu6md3nf3sh3daalfc",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://37jo5is64re6bknj2mh3b53rsq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2ytddcysjnfnlodsc7u2qjzuwq",
    "aws_content_delivery_bucket": "appointment-20200608005519-hostingbucket-main",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dywaytkavv7me.cloudfront.net"
};


export default awsmobile;
