import React, { useEffect, useState, useReducer  } from 'react'
import { JsonForms } from '@jsonforms/react';
import { materialRenderers } from '@jsonforms/material-renderers';
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { validateAppointmentDetails, scheduleAppointment } from '../../graphql/mutations'
import { listAppointmentTypes, getAppointmentsByVehiclePlate } from '../../graphql/queries';
import { getProceedingInfo } from '../../graphql/queries';
import { onCreateAppointment } from '../../graphql/subscriptions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import SolicitantForm from './components/SolicitantForm';
import AppointmentTypeForm from './components/AppointmentTypeForm';
import SlotSelector from './components/SlotSelector';
import AppointmentConfirmation from './components/AppointmentConfirmation';
import ReCAPTCHA from 'react-google-recaptcha';
import awsconfig from '../../aws-exports';
import  {format, parse, addDays} from "date-fns";
import { es } from 'date-fns/locale';
import appTypeSystemName from '../appTypeSystemName';
Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  }
}));

const steps = ['Tipo de Cita', 'Solicitante', 'Fecha'];

export default function Appointment() {
  const initialState = { name: '', lastname: '', identification: '', phone: '', email: '', emailError: '', slot: '', appointmentType: '', appointmentTypeObject: {}, group: "" };
  const [stateData, setData] = useState();
  const [formState, setFormState] = useState(initialState)
  const [appointmentTypes, setAppointmentTypes] = useState([])
  const [catpchaToken, setCatpchaToken] = useState('')
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [appointmentData, setAppointmentData] = useState()
  const [loading, setLoading] = useState(false);
  const [slot, setSlot] = useState({ slot: '' })
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [warningMessages, setWarningMessages] = useState([]);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [appointmentConfirmationData, setAppointmentConfirmationData] = useState();
  
  const htmlTemplate = '<div style="font-size: 12px; margin: 10px; border: 1px solid black; width: 600px;"><div style="margin: 10px;"><div style="text-align: center;"><img src="http://static.tustributos.com/Panama/Pa.Panama/Ari/logo_panama_new.png" width="100" /></div><p style="text-align: right;">Fecha : @currentDate</p><p style="text-align: justify;">Estimado Contribuyente, por este medio le confirmamos que su Cita para <strong>@appointmentType</strong> ha sido agendada exitosamente para el d&iacute;a @appointmentDate a nombre de @fullName con c&eacute;dula @idCard</p><h3>IMPORTANTE:</h3><div style="margin: 10px;">@requirements</div></div></div>';

  
  useEffect(() => {
    fetchAppointmentType()
  }, [])

 
  const recaptchaRef = React.createRef()

  var handleCaptcha = function(key) {
    setInput("captcha", key);
    const response = key;
    setCatpchaToken(response);
  }


  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AppointmentTypeForm  appointmentTypes={appointmentTypes} formState={formState} onChange={handleInputChange} formId={"AppointmentTypeForm"} />;
      case 1:
        const appForm = JSON.parse(formState.appointmentTypeObject.form);
        return (
          <div>
          <SolicitantForm  appointmentTypes={appointmentTypes} formState={formState} onChange={handleInputChange} formId={"ApplicantForm"} />

          <form validate="true" id="jsonform"><JsonForms
            schema={appForm.schema}
            uischema={appForm.uischema}
            data={stateData}
            renderers={materialRenderers}
            onChange={({ errors, data }) => setData(data)}
          />
        </form>
        </div>
        );
      case 2:
        return <SlotSelector appointmentData={appointmentData}  formState={formState} onChange={handleInputChange} onSlotSelected={setSlot}/>;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleInputChange = (event) => {

    if (event.target === undefined) {
      return;
    }

    if (event.target.name == "appointmentType") {

      const ob = appointmentTypes.find(appType => {
        return appType.id == event.target.value
      });

      setFormState({ ...formState,
        appointmentType: event.target.value,
        appointmentTypeObject: ob
      });
      setData(JSON.parse(ob.form).initialData);
    }
    else {
      if(event.target.name == "identification"){
        if(validateIdentification(event.target.value)){
          setInput(event.target.name, event.target.value.toUpperCase());
        }
        else{
          alert("La identificación solo puede contener el separador de guion (-)");
        }
      }
      else{
        setInput(event.target.name, event.target.value);
      }
    }

  }
  function validateIdentification(idValue){
    var regexp = /^[a-zA-Z0-9-]+$/;
        if(idValue ===""){
          return true;
        }
        else if (idValue.search(regexp) === -1){
          return false;
        }
        else{
          return true;
        }
  }
  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchAppointmentType() {
    try {
      const AppointmentTypeData = await API.graphql({ ...graphqlOperation(listAppointmentTypes), authMode: "API_KEY" });
      const AppointmentTypes = AppointmentTypeData.data.listAppointmentTypes.items;
      setAppointmentTypes(AppointmentTypes);
    }
    catch (err) {
      console.log('error fetching AppointmentTypes', err);
    }
  }

  function calculateGroup() {
    const idLast = formState.identification.substring(formState.identification.length - 1, formState.identification.length);
    //const gender = stateData.gender.substring(0, 1);
    var group = "G";// gender;// + idLast;

    /*if (stateData.senior) {
      return gender + "T";
    }*/

    return group;

  }

  function validateEmail() {
    let isError = false;
    const emailError = "El correo no es válido";
    if (formState.email.indexOf("@") === -1) {
      isError = true;
    }
    if (isError) {
      setInput("emailError", emailError);

    }
  }
  Date.prototype.today = function () { 
    return ((this.getDate() < 10)?"0":"") + this.getDate() +"/"+(((this.getMonth()+1) < 10)?"0":"") + (this.getMonth()+1) +"/"+ this.getFullYear();
  }
  //Se llena plantilla html a enviar por correo
  function getFilledEmailTemplate(){
    var newDate = new Date();
    if (activeStep == 2) {
        var date = format(parse(slot.date,'yyyyMMdd',new Date()),'dd/MM/yyyy',{locale:es});
        var emailTemplate = htmlTemplate.replace("@appointmentDate", date)
                        .replace("@appointmentType", formState.appointmentTypeObject.title)
                        .replace("@fullName", formState.name +" " + formState.lastname)
                        .replace("@idCard", formState.identification)
                        .replace("@requirements", formState.appointmentTypeObject.requirements)
                        .replace("@currentDate",  newDate.today());
        return emailTemplate;
     }
     else{
       return "Su cita se ha programado exitosamente!";
     }
   
  }
  function setAppointmentConfirmation() {
    
      var appo = {
        name: formState.name,
        lastname: formState.lastname,
        identification: formState.identification,
        phone: formState.phone,
        email: formState.email,
        details: JSON.stringify(stateData),
        appointmentSlotId: formState.slot,
        date: slot.date,
        appointmentSlot: slot.slot,
        appointmentAppointmentTypeId: formState.appointmentTypeObject.id,
        appointmentAppointmentTypeTitle: formState.appointmentTypeObject.title,
        captcha: formState.captcha,
        requirements: formState.appointmentTypeObject.requirements
      };
      setAppointmentConfirmationData(appo);
  }
  async function updateJsonData(){
 
     switch (formState.appointmentTypeObject.systemName.toLowerCase()) {
       //  Cita para Notificación
      case appTypeSystemName.appTypeSysNamePubNotification.toLowerCase():
         if (activeStep == 1) {
           //Actualizo numero de contribuyente
          const proceedingInfo = await API.graphql({ ...graphqlOperation(getProceedingInfo, {proceedingNumber: stateData.proceedingNumber }), authMode: "API_KEY" });
          stateData.accountNumber = (proceedingInfo.data.getProceedingInfo.accountNumber === null || undefined) ? "" : proceedingInfo.data.getProceedingInfo.accountNumber ;
          setData(stateData);

         }
            
      break;
      default:
      break;
     }
      return stateData;
  }

  const handleNext = async(event) => {
    try {
      var detail = await updateJsonData();
      var emailTemplate = getFilledEmailTemplate();
      var form = {
        name: formState.name,
        lastname: formState.lastname,
        identification: formState.identification,
        phone: formState.phone,
        email: formState.email,
        details: JSON.stringify({}),// JSON.stringify(detail),
        appointmentSlotId: formState.slot,
        date: slot.date,
        appointmentSlot: slot.slot,
        appointmentAppointmentTypeId: formState.appointmentTypeObject.id,
        captcha: formState.captcha,
        emailTemplate: emailTemplate
      };
      
      var appo = {
        ...form,
        ...JSON.parse(JSON.stringify(detail))
      };
      console.log(appo);
      setAppointmentData(appo);
      //Valor a enviar a pagina de confirmacion
      setAppointmentConfirmation();
      //Metodo de validaciones
      //validateEmail();
      ValidateHandleNext();

      //Citas de Retiro de Placa se deshabilitan 
      /*
      if(activeStep ===0){
        if(formState.appointmentTypeObject.systemName.toString().toLowerCase() == appTypeSystemName.appTypeSysNameVehiclePlate.toLowerCase()){
           throw new ValidateHandleNextException("Estimado contribuyente,  se reanuda la atención sin previa cita en el Centro de Entrega de Placas en horario regular");

        }
      }*/
      
      //Detalles de la cita
      if (activeStep == 1) {

        var validateAppointmentDetailsInput = {
          name: appo.name,
          lastname: appo.lastname,
          identification: appo.identification,
          phone: appo.phone,
          email: appo.email,
          //details: appo.details,
          appointmentAppointmentTypeId: formState.appointmentTypeObject.id,
	        appointmentTypeSystemName: formState.appointmentTypeObject.systemName
        };
    
        validateAppointmentDetailsInput = {
          ...validateAppointmentDetailsInput,
          ...JSON.parse(JSON.stringify(detail))
        };
      
        setLoading(true);
        
        var resultValidate = await API.graphql({ ...graphqlOperation(validateAppointmentDetails, { input: validateAppointmentDetailsInput }), authMode: "API_KEY" });
        console.log(resultValidate);
        resultValidate = resultValidate.data.validateAppointmentDetails;
        if(formState.appointmentTypeObject.systemName.toString().toLowerCase() == appTypeSystemName.appTypeSysNameVehiclePlate.toLowerCase()) {
            var group = calculateGroup();
            setFormState({ ...formState, group: group });
        }
        else{
          setFormState({ ...formState, group: formState.appointmentTypeObject.systemName });
        }
    
        if (resultValidate.errors.length !== undefined && resultValidate.errors.length > 0 && resultValidate.errors[0] !== '') {
          throw new ValidateHandleNextException(resultValidate.errors[0]);
        }

        if (resultValidate.warnings.length !== undefined && resultValidate.warnings.length > 0) {
          setWarningMessages(resultValidate.warnings);
          setOpenWarning(true);
        }
  
        setLoading(false);
      }

      if (activeStep == steps.length - 1) {
        setLoading(true);
        
        if(appo.vehiclePlateNumber !== undefined && appo.vehiclePlateNumber != "")
        {
          const queryParams = {
            vehiclePlateNumber: appo.vehiclePlateNumber == undefined ? '' : appo.vehiclePlateNumber,
            date: {
                  eq: appo.date // filter priority = 1
              },
            sortDirection: 'DESC',
          };
  
          debugger;
          let queryResult = await API.graphql({...graphqlOperation(getAppointmentsByVehiclePlate, 
                                            queryParams), 
                                            authMode: "API_KEY"
          });
          
          console.log(queryResult);
          if(queryResult.data.GetAppointmentsByVehiclePlate.items.length > 0)
          {
            throw new ValidateHandleNextException("Ya existe una cita para este número de placa!");
          }
        }
        
        
        const resp = await API.graphql({ ...graphqlOperation(scheduleAppointment, { input: appo }), authMode: "API_KEY" });
        console.log('resp',resp);
        //setAppointmentData(resp.data.scheduleAppointment);
      }

      setActiveStep(activeStep + 1);

    }
    catch (error) {
      if (error instanceof ValidateHandleNextException) {
        setErrorMessage(error.message);
        setOpen(true);
        return;
      }
      else {
        console.log("Error", error);
        var errorMessage = "Ha ocurrido un error al enviar su solicitud, intente  nuevamente.";
        if (error.errors != undefined && error.errors !== null && error.errors.length > 0) {
          const resultError = JSON.parse(error.errors[0].errorType);          
          errorMessage = error.errors[0].message;
          errorMessage = errorMessage.replace("{date}", format(parse(resultError.date,'yyyyMMdd',new Date()),'EEEE dd/MM/yyyy',{locale:es}));
          errorMessage = errorMessage.replace("{appointmentSlot}", resultError.appointmentSlot);
        }
        setErrorMessage(errorMessage);
        setOpen(true);
      }
    }
    finally {
      setLoading(false);
    }
  }

  function ValidateHandleNextException(message) {
    this.message = (message || "");
  }

  ValidateHandleNextException.prototype = new Error();


  function ValidateHandleNext() {
    //1.Trámite
    if (activeStep == 0) {
      if (formState.appointmentTypeObject.id == null) {
        throw new ValidateHandleNextException("Debe ingresar el tipo de cita a realizar!");
      }
    }
    //2. Datos del Solicitante
    if (activeStep == 1) {

      if (!document.getElementById("ApplicantForm").getElementsByTagName("form")[0].checkValidity()) {
        throw new ValidateHandleNextException("Debe ingresar todos los campos requeridos!");
      }
      else if (formState.identification.length < 5) {
        throw new ValidateHandleNextException("El número de cédula es inválido")
      }

      if (!document.getElementById('jsonform').checkValidity()) {
        throw new ValidateHandleNextException("Debe ingresar todos los campos requeridos!");
      }
      //NEW VALIDATIONS BY APPOINTMENT TYPE
      validateFieldsJsonForm();
    }

    //3. Envío de solicitud
    if (activeStep == 2) {
      if (catpchaToken.length === undefined || catpchaToken.length === null || catpchaToken.length == 0) {
        throw new ValidateHandleNextException("Favor validar que no es un robot");
      }
      else if (slot === '') {
        throw new ValidateHandleNextException("Debe seleccionar el horario de la cita");
      }
    }

  }
  /// Funcion para validar los campos de los jsonForm por cada tipo de cita
  function validateFieldsJsonForm() {
    
    switch (formState.appointmentTypeObject.systemName.toLowerCase()) {
      
      // 1. Retiro de Placa
      case appTypeSystemName.appTypeSysNameVehiclePlate.toLowerCase():
        /*if (stateData.gender === undefined) {
          throw new ValidateHandleNextException("Debe seleccionar el género");
        }
        else */if (stateData.vehiclePlateNumber === undefined || stateData.vehiclePlateNumber === "") {
          throw new ValidateHandleNextException("Debe ingresar el número de placa");
        }
        
        
      break;
      // 2. Cita para consulta de trámites
      case appTypeSystemName.appTypeSysNamePubConsult.toLowerCase():
        if (stateData.typeActor.toLowerCase() == "seleccione") {
          throw new ValidateHandleNextException("Favor seleccionar el tipo de persona antes de continuar");
        }
        else {
          //Persona Natural
          if (stateData.typeActor.toLowerCase() == "natural") {
            //si no es propietario del tramite
            if (!stateData.isOwnerProceeding) {
              if (stateData.idCard == "") {
                throw new ValidateHandleNextException("Favor ingrese la Cédula/Pasaporte del propietario del trámite!");
              }
              else if (stateData.firstName == "") {
                throw new ValidateHandleNextException("Favor ingrese el nombre del propietario del trámite!");
              }
              else if (stateData.lastName == "") {
                throw new ValidateHandleNextException("Favor ingrese el apellido del propietario del trámite!");
              }
              else if (stateData.accountNumber == "") {
                throw new ValidateHandleNextException("Favor ingrese el Número de Contribuyente del propietario del trámite!");
              }
              else if (stateData.typeProceeding != null && (stateData.typeProceeding.toLowerCase() == "seleccione" || stateData.typeProceeding == "")) {
                throw new ValidateHandleNextException("Favor ingrese el Tipo de Trámite a consultar!");
              }
              else if (stateData.comment != null && stateData.comment == "") {
                throw new ValidateHandleNextException("Favor ingrese el Motivo de consulta!");
              }
              else if (stateData.capacity === undefined || stateData.capacity == "") {
                throw new ValidateHandleNextException("Favor ingrese la Capacidad!");
              }
              else { }
            }
            //Si es propietario del tramite
            else {
              if (stateData.accountNumber == "") {
                throw new ValidateHandleNextException("Favor ingrese el Número de Contribuyente del propietario del trámite!");
              }
              else if (stateData.typeProceeding != null && (stateData.typeProceeding.toLowerCase() == "seleccione" || stateData.typeProceeding == "")) {
                throw new ValidateHandleNextException("Favor ingrese el Tipo de Trámite a consultar!");
              }
              else if (stateData.comment != null && stateData.comment == "") {
                throw new ValidateHandleNextException("Favor ingrese el Motivo de consulta!");
              }
            }
          }
          //Persona Juridica
          else if (stateData.typeActor.toLowerCase() == "jurídico") {
            if (stateData.businessName == "") {
              throw new ValidateHandleNextException("Favor ingrese la Razón Social!");
            }
            else if (stateData.ruc == "") {
              throw new ValidateHandleNextException("Favor ingrese el RUC");
            }
            else if (stateData.accountNumber == "") {
              throw new ValidateHandleNextException("Favor ingrese el Número de Contribuyente del propietario del trámite!");
            }
            else if (stateData.typeProceeding != null && (stateData.typeProceeding.toLowerCase() == "seleccione" || stateData.typeProceeding == "")) {
              throw new ValidateHandleNextException("Favor ingrese el Tipo de Trámite a consultar!");
            }
            else if (stateData.comment != null && stateData.comment == "") {
                throw new ValidateHandleNextException("Favor ingrese el Motivo de consulta!");
              }
            else if (stateData.capacity === undefined || stateData.capacity == "") {
              throw new ValidateHandleNextException("Favor ingrese la Capacidad!");
            }
            else { }
          }
          else { }
        }
        break;
       // 3. Cita para Notificación
      case appTypeSystemName.appTypeSysNamePubNotification.toLowerCase():
        if (stateData.typeActor.toLowerCase() == "seleccione") {
          throw new ValidateHandleNextException("Favor seleccionar el tipo de persona antes de continuar");
        }
        else {
          //Persona Natural
          if (stateData.typeActor.toLowerCase() == "natural") {
            //si no es propietario del tramite
            if (!stateData.isOwnerProceeding) {
              if (stateData.idCard == "") {
                throw new ValidateHandleNextException("Favor ingrese la Cédula/Pasaporte del propietario del trámite!");
              }
              else if (stateData.firstName == "") {
                throw new ValidateHandleNextException("Favor ingrese el nombre del propietario del trámite!");
              }
              else if (stateData.lastName == "") {
                throw new ValidateHandleNextException("Favor ingrese el apellido del propietario del trámite!");
              }
              else if (stateData.proceedingNumber == "") {
                throw new ValidateHandleNextException("Favor ingrese el Número de trámite a consultar!");
              }
              else if (stateData.capacity === undefined || stateData.capacity == "") {
                throw new ValidateHandleNextException("Favor ingrese la Capacidad!");
              }
              else { }
            }
            //Si es propietario del tramite
            else {
              if (stateData.proceedingNumber == "") {
                throw new ValidateHandleNextException("Favor ingrese el Número de trámite a consultar!");
              }
            }
          }
          //Persona Juridica
          else if (stateData.typeActor.toLowerCase() == "jurídico") {
            if (stateData.businessName == "") {
              throw new ValidateHandleNextException("Favor ingrese la Razón Social!");
            }
            else if (stateData.ruc == "") {
              throw new ValidateHandleNextException("Favor ingrese el RUC");
            }
          
            else if (stateData.proceedingNumber == "") {
              throw new ValidateHandleNextException("Favor ingrese el Número de trámite a consultar!");
            }
            else if (stateData.capacity === undefined || stateData.capacity == "") {
              throw new ValidateHandleNextException("Favor ingrese la Capacidad!");
            }
            else { }
          }
          else {
          }
        }
        break;
     // 4. DOYC - Ingreso de Planos (Originales o Misceláneos)
     case appTypeSystemName.appTypeSysNameOYCIP.toLowerCase():
       if (stateData.projectType === undefined || stateData.projectType === "") {
          throw new ValidateHandleNextException("Debe ingresar Tipo de Proyecto!");
        }
        else{}
        break;
     // 5. DOYC - Reingreso de Planos (Corregido)
     case appTypeSystemName.appTypeSysNameOYCRPC.toLowerCase():
       if (stateData.planeNumber === undefined || stateData.planeNumber === "") {
          throw new ValidateHandleNextException("Debe ingresar Número del plano!");
        }
        else if (stateData.proceedingNumber === undefined || stateData.proceedingNumber === "") {
          throw new ValidateHandleNextException("Debe ingresar Número de trámite de plano!");
        }
        else if (stateData.projectName === undefined || stateData.projectName === "") {
          throw new ValidateHandleNextException("Debe ingresar Nombre del Proyecto!");
        }
        break;
     // 6. DOYC - Retiro de Planos (Aceptado o Pendiente)
     case appTypeSystemName.appTypeSysNameOYCRPA.toLowerCase():
       debugger;
       if (stateData.planeNumber === undefined || stateData.planeNumber === "") {
          throw new ValidateHandleNextException("Debe ingresar Número del plano!");
        }
        else if (stateData.proceedingNumber === undefined || stateData.proceedingNumber === "") {
          throw new ValidateHandleNextException("Debe ingresar Número de trámite de plano!");
        }
        else if (stateData.projectName === undefined || stateData.projectName === "") {
          throw new ValidateHandleNextException("Debe ingresar Nombre del Proyecto!");
        }
        break;
      default:
      // code block
    }
  }


  const handleBack = () => {
    if (formState.slot) {
      setInput("slot", "");
    }
    setActiveStep(activeStep - 1);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  
  const handleCloseWarning = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenWarning(false);
  }


  return (
    <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Solicitud de cita
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
            <Snackbar open={open} onClose={handleClose} >
              <Alert onClose={handleClose} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
            {
              warningMessages.map((message, i) => (
                <Snackbar open={openWarning} onClose={handleCloseWarning} autoHideDuration={9000}>
                  <Alert onClose={handleCloseWarning} severity="warning">
                    {message}
                  </Alert>
                </Snackbar>
              ))
            }
            
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <AppointmentConfirmation data={appointmentConfirmationData}></AppointmentConfirmation>
            ) : (
              <React.Fragment>
                
                  {getStepContent(activeStep)}
                 
                 <div className={classes.root}>
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? '800ms' : '0ms',
                  }}
                  unmountOnExit
                >
                <Box position="relative" display="inline-flex">
                  <CircularProgress />
                  <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="caption" component="div" color="textSecondary">
                   &nbsp;Conectandose a los sistemas para validar la información
                  </Typography>
                </Box>
                </Box>
                </Fade>
                </div>
                
                 {(activeStep === steps.length - 1 &&  slot.slot)? (
                <ReCAPTCHA 
                      onChange={handleCaptcha}
                      ref={recaptchaRef}
                       hl= "es"
                      //This ref can be used to call captcha related functions in case you need.
                      sitekey="6Lft2AAVAAAAAJB30dHi44tVH-g5F35hF51WKUtS"
                      theme="light"
                      
              
                    />
                ) : ""}
                <div className={classes.buttons}>
           
                   
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Atras
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    id="buttonNext"
                    display={loading}
                    disabled={loading || (activeStep === 2 && !slot.slot)}
                  >
                    {activeStep === steps.length - 1 ? 'Enviar' : 'Siguiente'}
                  </Button>
                 
                </div>
                
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
  );
}
