import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Appointment, AppointmentSearch, AdminAppointment, AdminSlots, AdminUploadSlots, AdminNewSlots, 
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/appointment"
      />
      <RouteWithLayout
        component={Appointment}
        exact
        layout={MinimalLayout}
        path="/appointment"
      />
      <RouteWithLayout
        component={AppointmentSearch}
        exact
        layout={MinimalLayout}
        path="/appointment-search"
      />
      <RouteWithLayout
        component={AdminAppointment}
        exact
        layout={MainLayout}
        path="/admin"
      />
       <RouteWithLayout
        component={AdminSlots}
        exact
        layout={MainLayout}
        path="/admin-slots"
      />
      <RouteWithLayout
        component={AdminUploadSlots}
        exact
        layout={MainLayout}
        path="/admin-upload-slots"
      />
        <RouteWithLayout
        component={AdminNewSlots}
        exact
        layout={MainLayout}
        path="/admin-new-slots"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
