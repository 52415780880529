import React , { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import DateFnsUtils from '@date-io/date-fns';
import  {format, parse, addDays} from "date-fns";
import { es } from 'date-fns/locale'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { getAvailableSlotsByAppointmentType } from '../../../graphql/queries';
import NumPad from 'react-numpad';
import appTypeSystemName from '../../appTypeSystemName';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


export default function SlotSelector({onChange,onSlotSelected, appointmentData, formState}) {

const classes = useStyles();
const [selectedDate, handleDateChange] = useState(new Date());
const [slots, setSlots] = useState([]);
const [slotSelected, setSlotSelectd] = useState();
const [datesAva, setDatesAva] = useState(new Map());
const [notAvailableSlotMessage, setNotAvailableSlotMessage] = useState('');
const [isAvailableSlots, setIsAvailableSlots] = useState(false);
const [appointmentDates, setAppointmentDates] = useState({});
const [appointmentValue, setAppointmentValue] = useState('');
  
  const onChangeDate = async(date) => {
    var formatDate = format(date, "yyyyMMdd");
    //fetchSlots(formatDate);
    handleDateChange(date);
  };
  
  useEffect(() => {
    fetchSlots(addDays(new Date(),1));
  }, [])

  const onChangeSlot = (event) => {
    onChange(event);
    var sl =slots.find(it => {
          return it.id == event.target.value
        });
    setSlotSelectd(sl);
    onSlotSelected(sl);
  };
  function showMessage(){
    if(slotSelected)
    {
      return  <Typography variant="body1" gutterBottom>
        Se agendará la cita para el día {format(parse(slotSelected.date,'yyyyMMdd',new Date()),'EEEE dd/MM/yyyy',{locale:es})} en el horario {slotSelected.slot} para {formState.name} {formState.lastname} con la identificación {formState.identification}, si esto es correcto por favor envie la solicitud.
        
      </Typography> 
    }
    else if(slots.length === 0){
      return  <Typography variant="body1" color="error" gutterBottom>
        {notAvailableSlotMessage}
      </Typography> 
    }
    else {
     return  <Typography/>
      
    }
  }
  async function fetchSlots(date) {
    try {
      //limpiando el slot seleccionado
      setSlotSelectd('');
      onSlotSelected('');
      formState.slot='';
      const filter = {
        and:[{ balance:{gt:0}},
        {group:{contains:formState.group}}
          ]
      }
      debugger
      const SlotsData = await API.graphql({ ...graphqlOperation(getAvailableSlotsByAppointmentType, {appointmentTypeId: formState.appointmentType,group:formState.group }), authMode: "API_KEY" });
      //console.
      var slotDataAvailable = SlotsData.data.getAvailableSlotsByAppointmentType.slots;
      //Tipo de cita retiro de placa se hace una consulta adicional
      var systemName = formState.appointmentTypeObject.systemName;
      if(systemName.toLowerCase() == appTypeSystemName.appTypeSysNameVehiclePlate.toLowerCase()){
        var groups = [];
        groups.push(systemName);
        const newSlotsData = await API.graphql({ ...graphqlOperation(getAvailableSlotsByAppointmentType, {appointmentTypeId: formState.appointmentType,group:systemName }), authMode: "API_KEY" });
        slotDataAvailable = slotDataAvailable.concat(newSlotsData.data.getAvailableSlotsByAppointmentType.slots);
      }
      //setear los slots que devuelva la consulta
      setSlots(slotDataAvailable);
      setIsAvailableSlots(slotDataAvailable.length==0);
      handleDateChange(parse(slotDataAvailable[0].date,'yyyyMMdd',new Date()));
      //Llenando data slots para calendar de appointments
      fetchDataForAppointmentSlot(slotDataAvailable);
      if(slots.length === 0){
        setNotAvailableSlotMessage('No hay horarios disponibles para cita')
      }else{
        setNotAvailableSlotMessage('')
      }
      
      let distinctDates = new Map();
      SlotsData.data.slotsbyAppointmentTypeByDateBySlot.items.forEach(item=> {
        if(!distinctDates.has(item.date))
        {
          distinctDates.set(item.date, true);
        }
      });
      setDatesAva(distinctDates);
      
      
      
    }
    catch (err) {
      console.log('error fetching AppointmentTypes',err);
    }
  }
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  function fetchDataForAppointmentSlot(slot) {
    var object = [];
    var schedules ={};

    slot.filter(obj => {
      object.push(obj.date);
    });
    object = object.filter(onlyUnique);
    
    for(var i = 0; i<object.length;i++){
       var resultSlots = slot.filter( obj => {
        return (obj.date === object[i]);
      });
      //horarios iguales por fecha
      var newObject =[];
      resultSlots.filter(sched =>{
        newObject.push(sched.slot);
      });
      var date = format(parse(object[i],'yyyyMMdd',new Date()),'dd/MM/yyyy',{locale:es});
      
      schedules[date] = newObject;
      console.log(schedules)      
    }
    console.log(schedules)      
    setAppointmentDates(schedules);

  }
  function createMarkup(htmlMarkup) {
    return {__html: htmlMarkup};
  }
  function onChangeAppointmentDate(value){
     setAppointmentValue(value);
  
     var date = format(parse(value.split(" ")[0],'dd/MM/yyyy',new Date()),'yyyyMMdd',{locale:es});
     var schedule = value.split(" ")[1];
     
     var adata = slots.filter(function( obj ) {
      return (obj.slot.startsWith(schedule)
              && obj.date === date
              )
     })[0];
     if(adata == null){
         adata = slots.filter(function( obj ) {
         return (obj.slot.startsWith(schedule)
              && format(parse(obj.date,'yyyyMMd',new Date()),'yyyyMMdd',{locale:es}) === date
              )
         })[0];
     }
     setSlotSelectd(adata);
     onSlotSelected(adata);
     formState.slot = adata.id;
  }
  
  return (
    <React.Fragment>
     {isAvailableSlots ? 
      ( <React.Fragment>
      <Typography variant="body1" gutterBottom>
                        {
                            <div dangerouslySetInnerHTML={createMarkup('<p align="justify" class="MuiTypography-root MuiStepLabel-label MuiTypography-body2 MuiTypography-displayBlock">Estimado contribuyente, por el momento no contamos con citas disponibles, favor de volver a intentar en unos minutos para validar.')} />
                        }
                    </Typography>
      </React.Fragment>) :
      (<React.Fragment>
      <Typography variant="h6" gutterBottom>
        Seleccione la cita
      </Typography>
       {slots.length ===0 ? 
          ( <React.Fragment>
               <div className={classes.root}>
                <LinearProgress />
                <LinearProgress color="secondary" />
               </div>
           </React.Fragment>) :
          (<React.Fragment>
            <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth={true}>
                
                <NumPad.Appointment
                  dateFormat={"DD/MM/YYYY"}
                  dates={appointmentDates}
                  locale={"es"}
                  onChange={onChangeAppointmentDate}
                  //position={"fullscreen"}
                >
                    <TextField
                    required
                    id="appointmentDate"
                    name="appointmentDate"
                    fullWidth
                    label={"Fecha Cita"}
                    value = {appointmentValue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </NumPad.Appointment>
              </FormControl>
            </Grid>
          </Grid>
           </React.Fragment>) 
       }
      
    
      <Grid container spacing={3}>
       <Grid item xs={12} style={{display: false ? 'block' : 'none' }}>
       <FormControl fullWidth={true}>
        <InputLabel id="slot-label">Horarios</InputLabel>
        <Select
          labelId="slot-label"
          id="slot"
          value={formState.slot}
          onChange={onChangeSlot}
          name="slot"
          required
        >
        {slots.map(item => (
       
               <MenuItem value={item.id} key={item.id}>{format(parse(item.date,'yyyyMMdd',new Date()),'EEEE dd/MMMM/yyyy',{locale:es})} - {item.slot}</MenuItem>
                 ))};
        </Select>
      </FormControl>
        </Grid>
        <Grid item xs={12}>
        { showMessage()}
        </Grid>
      </Grid>
    </React.Fragment>)}
    </React.Fragment>
  );
}