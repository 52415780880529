import React , { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid,  Button} from '@material-ui/core';
import { withAuthenticator, AmplifySignOut, AmplifyAuthenticator } from '@aws-amplify/ui-react';
import  {format, parse, addDays} from "date-fns";
import { es } from 'date-fns/locale';
import { readString } from 'react-papaparse';
import { CSVReader } from 'react-papaparse';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { createSlot } from '../../graphql/mutations';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {Paper,Fade, Box, CircularProgress, Typography, TextField,FormControl,Select,InputLabel} from '@material-ui/core';
import SlotsTable from './components/SlotsTable';
import { listSlots, listAppointmentTypes } from '../../graphql/queries';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  }
}));

const buttonRef = React.createRef();

function AdminUploadSlots(){
  const appTypeSystemName = {
    appTypeSysNameVehiclePlate : "VehiclePlateDelivery",
    appTypeSysNamePubConsult : "PCT",
    appTypeSysNamePubNotification : "PCN"
  };
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [data, setData] = React.useState([])
  const [originalData] = React.useState(data)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [countErrors, setCountErrors] = useState(0);
  const [appointmentTypeSelected, setAppointmentTypeSelected] = useState('');
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentTypeSystemName, setAppointmentTypeSystemName] = useState('');
  const uploadedFileLink = '../../../TemplateUploadAppointment.csv';

   useEffect(() => {
        fetchAppointmentType();
        
    }, []);
    async function fetchAppointmentType() {
    try {
    
      const AppointmentTypeData = await API.graphql({ ...graphqlOperation(listAppointmentTypes), authMode: "API_KEY" });
      const AppointmentTypes = AppointmentTypeData.data.listAppointmentTypes.items;
      setAppointmentTypes(AppointmentTypes);
    }
    catch (err) {
      console.log('error fetching AppointmentTypes', err);
    }
  }
   const onChange = (event) => {
     debugger
     console.log(appointmentTypes);
        if (event.target === undefined) {
            return;
        }
        if (event.target.name == "appointmentType") {
            setAppointmentTypeSelected(event.target.value);
            var appointmentTypeObject = appointmentTypes.filter(function( obj ) {
                    return obj.id == event.target.value;
            })[0];
            setAppointmentTypeSystemName(appointmentTypeObject.systemName);
        }
        
    }

//functions paparse
const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }

  const handleOnFileLoad = (data) => {
    debugger
      var newObject = [];
      setCountErrors(0);
      setCount(0);
       for (var i = 0; i < data.length; i++) {
         if(data[i].errors.length == 0){
           if(validateFormatDate(data[i].data.date))
           {
              data[i].data.id = uuid();
              newObject.push(data[i].data);
           }
           else{
             alert("Error en formato de fecha para slot " + data[i].data.slot + ", fecha recibida : " + data[i].data.date + " formato esperado yyyyMMdd.");
           }
            
         }
     }
     
    setData(newObject);
  }
  function validateFormatDate(date){
    var result = true;
    
    if(date.length < 8 || data.length > 8){
      result = false;
    }
    else if(data.length == 8){
      var year = data.substr(0,4);
      var month = data.substr(4,2);
      var day = data.substr(6,2);
      if (isNaN(year)){ 
         result = false;
      }
      else if (isNaN(month)){ 
         result = false;
      }
      else if (isNaN(day)){ 
         result = false;
      }
      
    }
    else {
    }
    
    
    return result;
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const handleOnRemoveFile = (data) => {
    console.log(data)
  }

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }
  
   const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  function ValidateHandleNextException(message) {
    this.message = (message || "");
  }

  ValidateHandleNextException.prototype = new Error();


  async function handleClickSaveSlot(event) {
      
      try{
        setCount(0);
        setCountErrors(0);
          if(appointmentTypeSelected === undefined || appointmentTypeSelected == "" ){
            alert("favor seleccione el tipo de cita a cargar");
            return;
          }
          else  if(data != null && data.length ==0) {
             alert("favor seleccione el archivo a cargar");
            return;
          }
          else {
              setLoading(true);
              var countSuccess = 0;
              var countError = 0
              
              for(var i = 0 ; i < data.length; i++)
              {
                  var filter = {};
                  var groups = [];
                  
                  //Quitando grupos ya que no aplica
                  /*
                   groups.push(data[i].group1);
                   if(data[i].group2 && data[i].group2!= "")
                   {
                       groups.push(data[i].group2);
                   }
                   */
                   //Ahora el grupo sera el systemName del tipo de cita
                   debugger
                   groups.push(appointmentTypeSystemName);
                   
               
                  //Validar si no se encuentra el registro antes de proseguir a insertarlo
                  if(groups.length == 1)
                  {
                      filter = { 
                      and: [
                            { date: { eq: parseInt(data[i].date) } },
                            { slot: { eq: data[i].slot } },
                            { slotAppointmentTypeId: { eq: appointmentTypeSelected } },
                            { balance: { gt: 0 } },
                            { group: { contains: groups[0] } }
                          ] 
                      
                       };
                  }
                  
                 if(groups.length == 2) {
                      filter = { 
                      and: [
                            { date: { eq: parseInt(data[i].date) } },
                            { slot: { eq: data[i].slot } },
                            { slotAppointmentTypeId: { eq: appointmentTypeSelected } },
                            { balance: { gt: 0 } },
                            { group: { contains: groups[0] } },
                             { group: { contains: groups[1]  } }
                          ] 
                      
                       };
                  }

                 let paramsToPush = {
                            id:data[i].id,
                            date: parseInt(data[i].date),
                            slot: data[i].slot,
      	                    slotAppointmentTypeId: appointmentTypeSelected,
      	                    group: groups,
      	                    capacity: parseInt(data[i].capacity),
      	                    balance: parseInt(data[i].capacity)
      	               
                  };
                  
                  debugger
                  var slotsData = await API.graphql(graphqlOperation(listSlots, { filter: filter }));
                  var appData = slotsData.data.listSlots.items;
                  if(appData.length == 0) {
                      while (slotsData.data.listSlots.nextToken) {
                
                          slotsData = await API.graphql(graphqlOperation(listSlots, { filter: filter, nextToken: slotsData.data.listSlots.nextToken }));
                          appData = appData.concat(slotsData.data.listSlots.items);
                           if(appData.length > 0) {
                               break;
                           }
                      }
                  }
                  // Si no se encuentra informacion registrada, se guarda el nuevo cupo
                  if(appData.length == 0) {
                    const resp = await API.graphql({ ...graphqlOperation(createSlot, { input: paramsToPush }) });  
                    countSuccess = countSuccess + 1;
                  }
                  else {
                    countError = countError + 1
                  }
                  setCount(countSuccess);      
                  setCountErrors(countError);
              }
              
              setCountErrors(countError);
              setData([]);
          }
        
        }
        catch (error) {
        if (error instanceof ValidateHandleNextException) {
          setErrorMessage(error.message);
          setOpen(true);
          setCount(countSuccess);  
          setCountErrors(countError);
          return;
        }
        else {
          console.log("Error", error);
          var errorMessage = "Ha ocurrido un error al enviar su solicitud, intente  nuevamente.";
          setErrorMessage(errorMessage);
          setOpen(true);
          setCount(countSuccess);  
          setCountErrors(countError);
        }
      }
      finally {
        setLoading(false);
      }
  }
 

    return(
        
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
          <React.Fragment>
    <Grid container spacing={1} alignItems="center">
    
    <Grid item xs={12}  sm={2}>
    <h2>Carga de Slots</h2>
              <FormControl fullWidth={true}>
                  <InputLabel id="appointmentType-label">Tipo de cita</InputLabel>
                  <Select
                    labelId="appointmentType-label"
                    id="appointmentType"
                    value={appointmentTypeSelected}
                    onChange={onChange}
                    name="appointmentType"
                    required
                    >
                    {appointmentTypes.map(appType => (
                          <MenuItem value={appType.id} key={appType.id}>{appType.title}</MenuItem>
                        ))}
                    </Select>
              </FormControl>
            
     </Grid>
    <Grid item xs={12} >
          
          
          <CSVReader
          ref={buttonRef}
          onFileLoad={handleOnFileLoad}
          onError={handleOnError}
          noClick
          config={{header: true}}
          noDrag
          onRemoveFile={handleOnRemoveFile}
        >
          {({ file }) => (
            <aside
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10
              }}
            >
            <Button variant="contained" color="default" onClick={handleOpenDialog} startIcon={<CloudUploadIcon />}>
                  Seleccionar Archivo

            </Button>
             
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#ccc',
                  height: 30,
                  lineHeight: 2.5,
                  marginTop: 5,
                  marginBottom: 5,
                  paddingLeft: 13,
                  paddingTop: 3,
                  width: '20%'
                }}
              >
                {file && file.name}
              </div>
              <Button variant="contained" color="secondary" onClick={handleRemoveFile}>
                Eliminar
            </Button>
            </aside>
          )}
        </CSVReader>
        
        <Button variant="contained" color="primary" onClick={handleClickSaveSlot} startIcon={<SaveIcon />}>
              Guardar Cupos
        </Button>   
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? '800ms' : '0ms',
            }}
            unmountOnExit
          >
          <Box position="relative" display="inline-flex">
            <CircularProgress />
            <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption" component="div" color="textSecondary">
             &nbsp;Guardando Datos....  {count}
            </Typography>
          </Box>
          </Box>
          </Fade>
          <Tooltip title="Descargar Plantilla">
            <a href={uploadedFileLink} target="_blank" rel="noopener noreferrer" download>
                                <IconButton aria-label="edit"  color="primary" 
                                >
                                 <CloudDownloadIcon fontSize="large"  />
                                </IconButton>
            </a>
            </Tooltip>
          
        </Grid>
         {count == 0 ? '' : (<React.Fragment>
            <Grid item xs={12} >
                    <Typography variant="subtitle1" gutterBottom>
                     <Box  fontWeight="fontWeightBold">
                         Se procesaron {count} registros exitosamente!
                     </Box>
                    </Typography>
            </Grid>
           </React.Fragment>) 
                    
                    
          }
          {countErrors == 0 ? '' : (<React.Fragment>
            <Grid item xs={12} >
            <Typography variant="subtitle1" gutterBottom color="error">
             <Box  fontWeight="fontWeightBold" color="text.error">
                 Se han encontrado {countErrors} registro (s) existente en el sistema.
             </Box>
            </Typography>
               </Grid>
            </React.Fragment>)
            
            
          }
        <Grid item xs={12}  >
            <SlotsTable
              rows = {data}
            />
          
        </Grid>
        
      </Grid>
              <Snackbar open={open} onClose={handleClose} >
              <Alert onClose={handleClose} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
          </React.Fragment>
          </div>
        );
}

export default withAuthenticator(AdminUploadSlots);