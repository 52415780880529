import React, {useEffect, useState} from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { updateSlot, deleteSlot } from '../../graphql/mutations'
import DateFnsUtils from '@date-io/date-fns';
import { format, parse, addDays } from "date-fns";
import MUIDataTable from "mui-datatables";
import { listSlots, listAppointmentTypes } from '../../graphql/queries';
import { makeStyles } from '@material-ui/core/styles';
import {Paper,Fade, Box, CircularProgress, Typography, Grid, TextField, Button} from '@material-ui/core';
import gridlanguage from "../gridlanguage.js";



import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';


import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

var options = gridlanguage.options;

  
  
      
function AdminSlots(){
  const columns = [
    {
          name: "id",
          label:"Acción",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Tooltip title="Editar">
                <IconButton aria-label="edit"  color="primary" onClick={e => { onEdit(value, tableMeta, updateValue)}} id="rowEdit">
                    <EditIcon />
                </IconButton>
                 </Tooltip>
              );
            }
          }
    },
    {
      name: "appointmentTypeTitle",
      label: "Tipo de cita",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "date",
      label: "Fecha",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
            return format(parse(value,'yyyyMMdd',new Date()), 'dd/MM/yyyy');
          }
      }
    },
    {
      name: "slot",
      label: "Horario",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "balance",
      label: "Saldo",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "capacity",
      label: "Capacidad",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "group",
      label: "Grupo",
      options: {
        filter: true,
        sort: false,
      }
    },
    {
      name: "elderly",
      label: "Tercera Edad?",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
            return value === 'SI'? <input type='checkbox' checked></input> : <input type='checkbox' ></input>;
          }
      }
    },
  ];

    const classes = useStyles();
    const [initialDate,setInitialDate] = useState(Date.now());
    const [finalDate,setFinalDate] = useState(Date.now()); 
    const [appointmentType, setAppointmentType] = useState();
    const [loading, setLoading] = useState(false);
    const [stateData, setData] = useState([]);
    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [appointmentTypeSelected, setAppointmentTypeSelected] = useState('');
    //New
    const [updateSlotInput, setUpdateSlotInput] = React.useState({});
    const [quantity, setQuantity] = React.useState(50);
    const [radioValue, setRadioValue] = React.useState('inc');
    const [openModal, setOpenModal] = React.useState(false);
    const [slotId, setSlotId] = React.useState('');
    const [loadingSave, setLoadingSave] = useState(false);
    const [balance, setBalance] = useState('');
    const [capacity, setCapacity] = useState('');
    const [filterBalance, setFilterBalance] = useState('');
    const [filterCapacity, setFilterCapacity] = useState('');
    
   
    
    
   useEffect(() => {
      fetchAppointmentType(); 
   }, []);  
   async function fetchAppointmentType() {
    try {
      const AppointmentTypeData = await API.graphql({ ...graphqlOperation(listAppointmentTypes), authMode: "API_KEY" });
      const AppointmentTypes = AppointmentTypeData.data.listAppointmentTypes.items;
      setAppointmentTypes(AppointmentTypes);
    }
    catch (err) {
      console.log('error fetching AppointmentTypes', err);
    }
  }
    
    function addExtraFilterCapacity(filter){
          switch (filterCapacity){
           case "eq":
              filter.and.push({capacity: {eq:capacity}});
             break;
            case "gt":
              filter.and.push({capacity: {eq:capacity}});
             break;
            case "lt":
              filter.and.push({capacity: {eq:capacity}});
             break;
            case "ge":
              filter.and.push({capacity: {eq:capacity}});
             break;
            case "le":
              filter.and.push({capacity: {eq:capacity}});
             break;
            default:
              filter.and.push({capacity: {eq:capacity}});
         }
    }
     function addExtraFilterBalance(filter){
       switch (filterBalance){
           case "eq":
              filter.and.push({balance: {eq:balance}});
             break;
            case "gt":
              filter.and.push({balance: {gt:balance}});
             break;
            case "lt":
              filter.and.push({balance: {lt:balance}});
             break;
            case "ge":
              filter.and.push({balance: {ge:balance}});
             break;
            case "le":
              filter.and.push({balance: {le:balance}});
             break;
            default:
              filter.and.push({balance: {eq:balance}});
         }
    }
    function getFilter(){
      var filter =  { 
          and: [
                { date: { ge: format(addDays(new Date(initialDate),1), "yyyyMMdd") } },
                { date: { le: format(addDays(new Date(finalDate),1), "yyyyMMdd") } },
                { slotAppointmentTypeId: { eq: appointmentTypeSelected } }
              ] 
         };
      if(balance != "" && capacity != ""){
          addExtraFilterBalance(filter);
          addExtraFilterCapacity(filter);
      }
      else if(balance != ""){
         addExtraFilterBalance(filter);
      }
      else if(capacity != ""){
         addExtraFilterCapacity(filter);
      }
      else {}
      
      return filter;
    }
    async function fetchSlots() {
    try{
      if(appointmentTypeSelected ===""){
        alert("Favor seleccione el tipo de cita para continuar con la búsqueda.");
        return;
      }
      setLoading(true);
      var date = addDays(new Date(initialDate), 1);
      console.log(initialDate);
      
      var filter = getFilter();
      
      var slotsData = await API.graphql(graphqlOperation(listSlots, { filter: filter }));
      var appData = slotsData.data.listSlots.items;
      while (slotsData.data.listSlots.nextToken) {
  
        slotsData = await API.graphql(graphqlOperation(listSlots, { filter: filter, nextToken: slotsData.data.listSlots.nextToken }));
        appData = appData.concat(slotsData.data.listSlots.items);
  
      }
      
      var result = [];
      appData.map(item => {
          result.push(
              {
                  id: item.id,
                  date: item.date, 
                  slot: item.slot, 
                  capacity: item.capacity, 
                  balance: item.balance, 
                  group: item.group.length > 0 ? item.group[0] : '',
                  elderly: item.group.length > 1 ? 'SI' : 'NO',
                  appointmentTypeTitle: item.appointmentType.title
              });
      })
      
      result = result.sort(compareApp);
      setData(result);
    }catch(e){
      console.log("Error fetching slots", e);
    }
    finally{
      setLoading(false);
    }

  }
  
  function compareApp(a, b) {
        if (a.date + a.appointmentSlot + a.identification + a.slot > b.date + b.appointmentSlot + b.identification + b.slot) {
          return 1
        }
        else {
          return -1
        }
      }
    function onEdit(value, tableMeta, updateValue){
      var rowIndex = tableMeta.rowIndex;
      var tableData = tableMeta.tableData;
     
      var adata = stateData.filter(function( obj ) {
          return obj.id === value;
      })[0];
      var updateSlot ={};
      updateSlot.id = adata.id;
      updateSlot.date = adata.date;
      updateSlot.slot = adata.slot;
      updateSlot.capacity = adata.capacity;
      updateSlot.balance = adata.balance;
      updateSlot.group = adata.group;
      
      setUpdateSlotInput(updateSlot);
      
      setSlotId(adata.id);
      
      
      setOpenModal(true);
    }
     //Metodos para modal
  const handleSliderChange = (event, newValue) => {
    setQuantity(newValue);
  };
  //Actualizacion de slot
  async function onRowUpdate() {
    try{
      
      setLoadingSave(true);
      if(quantity < 0){
          alert("La cantidad no puede ser menor a 0");
          return;
       }
      var filter = { 
          and: [
                { id: { eq:  slotId} }
              ] 
      };
      
      var slotsData = await API.graphql(graphqlOperation(listSlots, { filter: filter }));
      var appData = slotsData.data.listSlots.items;
      
      if(appData.length == 0) {
          while (slotsData.data.listSlots.nextToken) {
    
              slotsData = await API.graphql(graphqlOperation(listSlots, { filter: filter, nextToken: slotsData.data.listSlots.nextToken }));
              appData = slotsData.data.listSlots.items;
               if(appData.length > 0) {
                   break;
               }
          }
      }
      // Si se encuentra se procede a actualizar el saldo
      if(appData.length > 0) {
        var result = appData[0];
        var newBalance = 0;
        var newCapacity = 0;
        if(radioValue ==='inc'){
          newBalance = quantity + result.balance;
          newCapacity = quantity + result.capacity;
        }
        else if(radioValue === 'dec') {
          newBalance =  result.balance - quantity;
          newCapacity = result.capacity - quantity;
          newBalance = newBalance < 0 ? 0 : newBalance;
          newCapacity = newCapacity < 0 ? 0 : newCapacity;
        }
        var updateSlotInput  = {
          id: result.id,
          date: result.date,
          slot: result.slot,
          slotAppointmentTypeId: result.slotAppointmentTypeId,
          capacity: newCapacity, // nueva capacidad
          balance: newBalance, // nuevo saldo
          group: result.group
        }
        const resp = await API.graphql({ ...graphqlOperation(updateSlot, { input: updateSlotInput }) });
        
        var objIndex = stateData.findIndex((obj => obj.id == slotId));
        stateData[objIndex].balance= newBalance;
        stateData[objIndex].capacity= newCapacity;
        setData(stateData);
          
          
      }
      else {
         window.alert("registro no encontrado!");
      }
    }
    catch(ex){
      alert("Error al tratar de actualizar el registro seleccionado");
    }
    finally{
      setLoadingSave(false);
      setOpenModal(false);
      alert("Registro actualizado!");
    }
  };

  const handleInputQuantityChange = (event) => {
    setQuantity(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleInputQuantityBlur = () => {
    if (quantity < 0) {
        setQuantity(0);
    } else {
        setQuantity(quantity);
    }
  };
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };
  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleFilterBalanceChange = (event) => {
    setFilterBalance(event.target.value);
    if(event.target.value == ""){
      setBalance('');
    }
    
  };
  const handleFilterCapacityChange = (event) => {
    setFilterCapacity(event.target.value);
    if(event.target.value == ""){
      setCapacity('');
    }
  };
    
    return (
    <React.Fragment>
       <Grid container className={classes.root} spacing="1">
       
       <Grid item xs={12} >
       <TextField
          select
          label="Tipo de Cita"
          value={appointmentTypeSelected}
          onChange={e => setAppointmentTypeSelected(e.target.value)}
          style={{ width:'200px'}}
          
        >
        {appointmentTypes.map(appType => (
               <option value={appType.id} key={appType.id}>{appType.title}</option>
            ))}
        </TextField>
        &nbsp;&nbsp;&nbsp;
      <TextField
          id="date"
          label="Fecha Inicial"
          type="date"
          defaultValue="2020-06-15"
          value= {initialDate}
          onChange={e => setInitialDate(e.target.value)}
          className={classes.textField}
          rows="4"
          InputLabelProps={{
            shrink: true,
          }}
        />
        &nbsp;&nbsp;&nbsp;
        
        <TextField
          id="date"
          label="Fecha Final"
          type="date"
          defaultValue="2020-06-15"
          value= {finalDate}
          onChange={e => setFinalDate(e.target.value)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        &nbsp;&nbsp;&nbsp;
        <TextField
          select
          label="Filtro saldo"
          value={filterBalance}
          onChange={handleFilterBalanceChange}
          style={{ width:'200px'}}
        >
           <MenuItem value="">
            <em>Ninguno</em>
          </MenuItem>
          <MenuItem value={'eq'}>Igual</MenuItem>
          <MenuItem value={'gt'}>Mayor</MenuItem>
          <MenuItem value={'lt'}>Menor</MenuItem>
          <MenuItem value={'ge'}>Mayor ó Igual</MenuItem>
          <MenuItem value={'le'}>Menor ó Igual</MenuItem>
        </TextField>
        &nbsp;&nbsp;&nbsp;
          <TextField
          id="balance"
          label="Saldo"
          type="number"
          value= {balance}
          onChange={e => setBalance(e.target.value)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
          &nbsp;&nbsp;&nbsp;
        <TextField
          select
          label="Filtro capacidad"
          value={filterCapacity}
          onChange={handleFilterCapacityChange}
          style={{ width:'200px'}}
        >
        <MenuItem value="">
            <em>Ninguno</em>
          </MenuItem>
          <MenuItem value={'eq'}>Igual</MenuItem>
          <MenuItem value={'gt'}>Mayor</MenuItem>
          <MenuItem value={'lt'}>Menor</MenuItem>
          <MenuItem value={'ge'}>Mayor ó Igual</MenuItem>
          <MenuItem value={'le'}>Menor ó Igual</MenuItem>
        </TextField>
        &nbsp;&nbsp;&nbsp;
         <TextField
          id="capacity"
          label="Capacidad"
          type="number"
          value= {capacity}
          onChange={e => setCapacity(e.target.value)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>
        <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={ fetchSlots} disabled={loading}>
        Buscar
      </Button>
      
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit
      >
      <Box position="relative" display="inline-flex">
        <CircularProgress />
        <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
         &nbsp;Consultando información de los horarios
        </Typography>
      </Box>
      </Box>
      </Fade>
      </Grid>
       <Grid item xs={12} >
        <Dialog open={openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title" maxWidth ="md">
                <DialogTitle id="form-dialog-title">Ajuste de Cupo</DialogTitle>
                <DialogContent>
                <DialogContentText>
                        Para cambiar la cantidad de cupo asignado, por favor seleccione el tipo de operación a realizar y luego indique la cantidad.
                </DialogContentText>
                <FormControl component="fieldset">
                <FormLabel component="legend">Tipo Operación</FormLabel>
                <RadioGroup aria-label="gender" name="operation" value={radioValue} onChange={handleRadioChange}>
                        <FormControlLabel value="inc" control={<Radio />} label="Aumentar" />
                        <FormControlLabel value="dec" control={<Radio />} label="Reducir" />
                </RadioGroup>
                </FormControl>
                 <TextField
                        disabled 
                        id="slot"
                        name="slot"
                        label="Horario"
                        type="text"
                        value={updateSlotInput.slot}
                />
                <TextField
                        disabled 
                        id="balance"
                        name="balance"
                        label="Saldo actual"
                        type="number"
                        value={updateSlotInput.balance}
                />
                   <TextField
                        disabled 
                        id="balance"
                        name="balance"
                        label="Capacidad"
                        type="number"
                        value={updateSlotInput.capacity}
                />
            <Typography id="input-slider" gutterBottom>
                Cantidad
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                <AutorenewIcon />
                </Grid>
                <Grid item xs>
                <Slider
                    value={typeof quantity === 'number' ? quantity : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    max='500'
                    valueLabelDisplay = 'on'
                />
                </Grid>
                <Grid item>
                <Input
                    className={classes.input}
                    value={quantity}
                    margin="dense"
                    onChange={handleInputQuantityChange}
                    onBlur={handleInputQuantityBlur}
                    inputProps={{
                    step: 1,
                    min: 0,
                    max: 500,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                    }}
                />
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Cancelar
              </Button>
              <Button onClick={onRowUpdate} color="primary" disabled={loadingSave}>
                Guardar
              </Button>
              <Fade
                in={loadingSave}
                style={{
                  transitionDelay: loadingSave ? '800ms' : '0ms',
                }}
                unmountOnExit
              >
              <Box position="relative" display="inline-flex">
                <CircularProgress />
                <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="caption" component="div" color="textSecondary">
                 &nbsp;Guardando dato...
                </Typography>
              </Box>
              </Box>
              </Fade>
            </DialogActions>
          </Dialog>
      <MUIDataTable
      title={"Administración de Horarios"}
      data={stateData}
      columns={columns}
      options={options}
    />
    </Grid>
    </Grid>
    </React.Fragment>
  );
}

export default withAuthenticator(AdminSlots);