import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation, I18n } from 'aws-amplify'
import { withAuthenticator, AmplifySignOut, AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { listAppointments, listAppointmentTypes, getAppointmentsByDate } from '../../graphql/queries';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { format, parse, addDays } from "date-fns";
import MUIDataTable from "mui-datatables";
import {Fade, Box, CircularProgress, Typography} from '@material-ui/core';

import awsconfig from '../../aws-exports';
Amplify.configure(awsconfig);

I18n.setLanguage('es');
const dict = {
'es': {
        'Sign In': "Ingresar",
        'Sign Up': "Regístrate",
        'Sign in to your account':"",
        'Sign Out': "Salir",
        'Create Account': "Crear cuenta",
        'Enter your password':"Ingrese su contraseña",
        'Username *': "Usuario *",
        'Password *': "Contraseña *",
        'No account?':"Sin cuenta?",
        'Forgot your password?':"Olvidó su contraseña?",
        'Reset password': "Recordar contraseña",
        "Enter your username":"Ingrese su usuario",
        'Create account':"Crear cuenta",
        'Sign in to your account':"Administración de Sistema de Citas",
        'Reset your password':'Recordar contraseña',
        'Send code': "Enviar",
        'Back to Sign In':"Volver"
        
    }
};

I18n.putVocabularies(dict);


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


function AdminAppointment() {
  const [stateData, setData] = useState([]);
  const classes = useStyles();
  const [dateSelected, setDateSelected] = useState(new Date())
  const [loading, setLoading] = useState(false);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentTypeSelected, setAppointmentTypeSelected] = useState('');
  useEffect(() => {
      fetchAppointmentType(); 
   }, []);  
   async function fetchAppointmentType() {
    try {
      const AppointmentTypeData = await API.graphql({ ...graphqlOperation(listAppointmentTypes), authMode: "API_KEY" });
      const AppointmentTypes = AppointmentTypeData.data.listAppointmentTypes.items;
      setAppointmentTypes(AppointmentTypes);
    }
    catch (err) {
      console.log('error fetching AppointmentTypes', err);
    }
  }


  async function fetchAppointments() {
    try{
       if(appointmentTypeSelected ===""){
        alert("Favor seleccione el tipo de cita para continuar con la búsqueda.");
        return;
      }
      setLoading(true);
      
      var date = addDays(new Date(dateSelected), 1);
      var filter = {
        and: [
              { date: { eq: format(date, "yyyyMMdd") } },
              { appointmentAppointmentTypeId: { eq: appointmentTypeSelected } }
            ]
      };
      
      ////New Query
      const queryAppointmentInput = {
        appointmentType : appointmentTypeSelected,
        date :  format(date, "yyyyMMdd")
      };
    
      var AppointmentData = await API.graphql({ ...graphqlOperation(getAppointmentsByDate, {input: queryAppointmentInput }), authMode: "API_KEY" });
      var appData = AppointmentData.data.getAppointmentsByDate.items;

      ////End of New Query
      debugger
      //var AppointmentData = await API.graphql(graphqlOperation(listAppointments, { filter: filter }));
      //var appData = AppointmentData.data.listAppointments.items;
      while (AppointmentData.data.getAppointmentsByDate.nextToken) {
        //var newData =     await API.graphql({ ...graphqlOperation(getAppointmentsByDate, { input: queryAppointmentInput, nextToken: AppointmentData.data.getAppointmentsByDate.nextToken }), authMode: "API_KEY"} );
        //console.log(newData);
       // AppointmentData = await API.graphql({ ...graphqlOperation(getAppointmentsByDate, {nextToken: AppointmentData.data.getAppointmentsByDate.nextToken}), authMode: "API_KEY" });
        queryAppointmentInput['nextToken'] = AppointmentData.data.getAppointmentsByDate.nextToken;
        AppointmentData = await API.graphql({ ...graphqlOperation(getAppointmentsByDate, {input: queryAppointmentInput }), authMode: "API_KEY" });
        //AppointmentData = await API.graphql(graphqlOperation(getAppointmentsByDate, { input: queryAppointmentInput, nextToken: AppointmentData.data.getAppointmentsByDate.nextToken }));
        console.log(AppointmentData);
        appData = appData.concat(AppointmentData.data.getAppointmentsByDate.items);
  
      }
  
      function compareApp(a, b) {
        if (a.date + a.appointmentSlot + a.identification > b.date + b.appointmentSlot + b.identification) {
          return 1
        }
        else {
          return -1
        }
      }
      
      //AddDetails(appData);
      appData = appData.sort(compareApp);
      setData(appData);
    }catch(e){
      console.log("Error fetching appointments", e);
    }
    finally{
      setLoading(false);
    }

  }
  function AddDetails(appData){
    try{
          for (var i=0, n=appData.length; i < n; ++i){
              var jsonDetail = appData[i].details ? JSON.parse(appData[i].details): "";
              appData[i].proceedingNumber = jsonDetail.proceedingNumber ? jsonDetail.proceedingNumber: "";
              appData[i].accountNumber = jsonDetail.accountNumber ? jsonDetail.accountNumber: "";
              appData[i].typeProceeding = jsonDetail.typeProceeding ? jsonDetail.typeProceeding: "";
              appData[i].vehiclePlateNumber = jsonDetail.vehiclePlateNumber ? jsonDetail.vehiclePlateNumber: "";

          }
    }
    catch(ex){
       console.log("Error llenando detalles de cita "+ ex);
    }

  }
  const onChangeDate = (event) => {
    setDateSelected(event.target.value);
  };

  const columns = [
    {
      name: "date",
      label: "Fecha",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
            return format(parse(value,'yyyyMMdd',new Date()), 'dd/MM/yyyy');
          }
      }
    },
    {
      name: "appointmentSlot",
      label: "Hora",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "identification",
      label: "Identificación",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "name",
      label: "Nombres",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "lastname",
      label: "Apellidos",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "proceedingNumber",
      label: "Trámite",
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: "accountNumber",
      label: "Número contribuyente",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "typeProceeding",
      label: "Tipo de Trámite a consultar",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "vehiclePlateNumber",
      label: "Placa",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "phone",
      label: "Teléfono",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Correo",
      options: {
        filter: false,
        sort: false,
      },
    }
  ];

  const options = {
    filterType: 'checkbox',
    selectableRowsHideCheckboxes: true,
    selectableRows: 'none',
    print: false,
    textLabels: {
      body: {
        noMatch: "No se encontraron datos",
        toolTip: "Ordenar",
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar",
        print: "Imprimir",
        viewColumns: "Ver Columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "TODOS",
        title: "FILTROS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Columnas",
      },
      selectedRows: {
        text: "fila(s) seleccionada",
        delete: "Borrar",
        deleteAria: "Borrar filas seleccionadas",
      },
    }
  };


  return (
    <React.Fragment>
       <Grid container className={classes.root}>
       <Grid item xs={12}>
       <TextField
          select
          label="Tipo de Cita"
          value={appointmentTypeSelected}
          onChange={e => setAppointmentTypeSelected(e.target.value)}
          style={{ width:'200px'}}
          
        >
        {appointmentTypes.map(appType => (
               <option value={appType.id} key={appType.id}>{appType.title}</option>
            ))}
        </TextField>
        &nbsp;&nbsp;&nbsp;
      <TextField
          id="date"
          label="Fecha"
          type="date"
          defaultValue="2020-06-15"
          value= {dateSelected}
          onChange={onChangeDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        &nbsp;
        <Button variant="contained" color="primary" onClick={ fetchAppointments}>
        Buscar
      </Button>
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit
      >
      <Box position="relative" display="inline-flex">
        <CircularProgress />
        <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
         &nbsp;Consultando información de las citas
        </Typography>
      </Box>
      </Box>
      </Fade>
       </Grid>
       <TableContainer component={Paper}>
      <MUIDataTable
      title={"Citas Programadas"}
      data={stateData}
      columns={columns}
      options={options}
    />
    </TableContainer>
     </Grid>
    </React.Fragment>
  );
}

export default withAuthenticator(AdminAppointment);
